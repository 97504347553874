import React, { useState } from 'react'

import './Calendar.css'
import Calendar from 'react-calendar'

import { Box, Colors } from '../../atoms'
import { ISchedule } from '@open-platform/interface/src/entity'
import { IonText } from '@ionic/react'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { useGetListSchedule } from '../../../modules/schedule'
import { useMediaQuery } from 'react-responsive'
import { SP_MAX_WIDTH } from '../../../setting'
import { addMonths } from 'date-fns'
import { EventModal } from './EventModal'
import { useSafeAreaInset } from '../../../libs'

const LabelSection = () => {
  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })
  return (
    <Box display={isSp ? 'flex' : 'block'}>
      <Box display="flex" alignItems="center" mr={isSp ? '8px' : '0px'} mb={isSp ? '0px' : '16px'}>
        <Box width="12px" height="12px" borderRadius="2px" backgroundColor={Colors.primary.default} mr="6px" />
        <IonText>
          <h5 style={{ color: Colors.secondary[1000] }}>シークレットセミナー</h5>
        </IonText>
      </Box>
      <Box display="flex" alignItems="center">
        <Box width="12px" height="12px" borderRadius="2px" backgroundColor={Colors.teritary.default} mr="6px" />
        <IonText>
          <h5 style={{ color: Colors.secondary[1000] }}>イベント</h5>
        </IonText>
      </Box>
    </Box>
  )
}

export const PageSchedule = () => {
  const { listSchedule = [] } = useGetListSchedule({})
  const [selectedSchedule, setSelectedSchedule] = useState<ISchedule | undefined>(undefined)
  const scheduleMap = useMemo(() => {
    const sl: { [key: string]: ISchedule } = {}
    listSchedule.forEach(item => (sl[format(item.startDatetime * 1000, 'yyyyMMdd')] = item))
    return sl
  }, [listSchedule])
  const { value: bottomInset } = useSafeAreaInset('bottom')

  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })

  const handleClickNavigation = (e: React.MouseEvent, add: number) => {
    e.preventDefault()
    e.stopPropagation()
    setStartDate(addMonths(startDate, add))
  }
  const [startDate, setStartDate] = useState(new Date())
  return (
    <Box position="relative" height="100%" display="flex" alignItems="center" overflow="scroll">
      {isSp && (
        <Box position="fixed" bottom="90px" right="0px" p="16px">
          <LabelSection />
        </Box>
      )}
      <Box>
        <Box
          position="relative"
          backgroundColor="white"
          borderRadius="12px"
          px={isSp ? '0px' : '32px'}
          py={isSp ? '0px' : '104px'}
          mb={isSp ? '80px' : '0px'}
        >
          <Box textAlign="center" mb="32px" position="relative">
            <Box display="flex" justifyContent="space-between" position="absolute" width="100%">
              <Box color="black" cursor="pointer" onClick={e => handleClickNavigation(e, -1)}>
                ＜
              </Box>
              <Box color="black" cursor="pointer" onClick={e => handleClickNavigation(e, 1)}>
                ＞
              </Box>
            </Box>
            <IonText>
              <h3 style={{ fontWeight: 'bold', color: '#2A2A2D', margin: 0 }}>セミナー / イベント日程</h3>
            </IonText>
          </Box>

          <Calendar
            showDoubleView={!isSp}
            next2Label=""
            prev2Label=""
            nextLabel=""
            prevLabel=""
            showNavigation={true}
            maxDetail="month"
            minDetail="year"
            activeStartDate={startDate}
            showNeighboringMonth={true}
            formatDay={(_, date) => format(date, 'd')}
            onClickDay={d => {
              const key = format(d, 'yyyyMMdd')
              const schedule = scheduleMap[key]
              if (schedule) setSelectedSchedule(schedule)
            }}
            tileClassName={a => {
              const schedule = scheduleMap[format(a.date, 'yyyyMMdd')]
              if (schedule) {
                return `has-schedule ${schedule.kind}`
              } else {
                return ''
              }
            }}
          />
          {!isSp && (
            <Box position="absolute" bottom={bottomInset + 20 + 'px'} right="0px" p="30px">
              <LabelSection />
            </Box>
          )}
        </Box>
      </Box>
      <EventModal schedule={selectedSchedule} onClose={() => setSelectedSchedule(undefined)} />
    </Box>
  )
}
