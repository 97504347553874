import React from 'react'
import { Box } from '../../atoms'
import { Course } from '../Course'
import { CourseCardList } from '../CourseCardList'
import { commentList } from '../CommentList/factory'
import { useGetSpecialVideoList, useGetVideo } from '../../../modules/video'
import { IonLoading } from '@ionic/react'
import { useGetListVideoPlayHistory } from '../../../modules/videoPlayHistory'
import { useGetListVideoFavorite } from '../../../modules/videoFavorite'
import { useMediaQuery } from 'react-responsive'
import { SP_MAX_WIDTH } from '../../../setting'

export interface PageVideoSignleProps {
  videoId: string
}

export const PageVideoSingle = ({ videoId }: PageVideoSignleProps) => {
  const { video } = useGetVideo({ videoId })
  const { videoPlayHistoryList } = useGetListVideoPlayHistory({ perPage: 16, page: 1 })
  const { videoFavoriteList } = useGetListVideoFavorite({ perPage: 16, page: 1 })
  const { videos = [] } = useGetSpecialVideoList()

  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })

  return video ? (
    <Box maxWidth="100%" pb="80px">
      <Box width="100%" mb="56px">
        <Course
          video={video}
          commentList={commentList}
          pdfUrl={video.term.textbookPath ? process.env.REACT_APP_GCS_URL + video.term.textbookPath : ''}
        />
      </Box>

      {videos.length > 0 && (
        <Box px={isSp ? '16px' : '0px'} pl={isSp ? '16px' : '32px'}>
          <Box mb="46" width="100%" overflow="scroll">
            <CourseCardList
              courseList={videos}
              isViewMore={true}
              title="特別動画「BASIC」"
              titleIcon="special"
              isSort={false}
            />
          </Box>
        </Box>
      )}

      <Box px={isSp ? '16px' : '0px'} pl={isSp ? '16px' : '32px'}>
        {videoPlayHistoryList.length > 0 && (
          <Box mb="46" width="100%" overflow="scroll">
            <CourseCardList
              courseList={videoPlayHistoryList.map(item => item.video)}
              isViewMore={true}
              title="最近見た動画"
              titleIcon="eye"
              isSort={false}
            />
          </Box>
        )}
        {videoFavoriteList.length > 0 && (
          <Box mb="46" width="100%" overflow="scroll">
            <CourseCardList
              courseList={videoFavoriteList.map(item => item.video)}
              isViewMore={true}
              title="お気に入り一覧"
              titleIcon="star"
              isSort={false}
            />
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <IonLoading isOpen={true} />
  )
}
