import React from 'react'
import { IonModal, IonText } from '@ionic/react'
import { Box, Button, Colors } from '../../atoms'
import './modal.css'

export interface ModalProps {
  title: string
  confirmLabel?: string
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
  onDismiss: () => void
}

export const ModalConfirm: React.FC<ModalProps> = ({
  title,
  confirmLabel = '決定',
  isOpen,
  onCancel,
  onConfirm,
  onDismiss,
  children
}) => {
  return (
    <IonModal cssClass="my-custom-class" isOpen={isOpen} onDidDismiss={onDismiss}>
      <Box height="100%" display="flex" justifyContent="center" alignItems="center" onClick={onDismiss}>
        <Box
          display="flex"
          flexDirection="column"
          p="16px"
          backgroundColor="white"
          borderRadius="12px"
          width="90%"
          maxWidth="400px"
          onClick={e => e.stopPropagation()}
        >
          <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <IonText>
              <h4 style={{ fontWeight: 'bold', color: Colors.secondary.default }}>{title}</h4>
            </IonText>
          </Box>
          <Box>{children}</Box>
          <Box flex="1 0 auto" display="flex" justifyContent="flex-end" cursor="pointer">
            <Box onClick={onCancel} display="flex" alignItems="center" mr="16px">
              <IonText>
                <h5 style={{ color: Colors.secondary[300] }}>キャンセル</h5>
              </IonText>
            </Box>
            <Button onClick={onConfirm}>{confirmLabel}</Button>
          </Box>
        </Box>
      </Box>
    </IonModal>
  )
}
