import React, { useCallback, useMemo } from 'react'
import { Box, Colors } from '../../atoms'
import { IonAvatar, IonIcon, IonText, IonRouterLink } from '@ionic/react'
import { person, trashOutline, pencilOutline } from 'ionicons/icons'
import { format } from 'date-fns'
import styled from 'styled-components'
import { IVideoComment } from '@open-platform/interface/src/entity'
import { useState } from 'react'
import { ModalConfirm } from '../../organisms'
import { useGetMe } from '../../../modules/me'
import { useDeleteVideoComment, useEditVideoComment } from '../../../modules/videoComment'
import { getImageUrl } from '../../../libs'
import { useMediaQuery } from 'react-responsive'
import { ModalTextEdit } from '../../organisms/ModalTextEdit'

export interface CommentListItemProps {
  width?: number | string
  comment: IVideoComment
  edit?: boolean
}

const StyledAvatar = styled(IonAvatar)`
  overflow: hidden;
  width: 40px;
  height: 40px;
  flex: 1 0 auto;
  margin-right: 8px;
`

const StyledLink = styled(IonRouterLink)`
  display: block;
  box-sizing: border-box;
  color: ${Colors.secondary.default};
  cursor: 'pointer';
`

export const CommentListItem = ({ width = '100%', comment, edit = true }: CommentListItemProps) => {
  const [openDelte, setOpenDelete] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const me = useGetMe().user
  const isMine = useMemo(() => me?.id === comment.user.id, [comment.user.id, me])
  const { handleDeleteVideoComment } = useDeleteVideoComment({ videoId: comment.video.id })
  const { handleEditVideoComment } = useEditVideoComment({ videoCommentId: comment.id, videoId: comment.video.id })

  const handleDeleteComment = useCallback(() => {
    setOpenDelete(false)
    handleDeleteVideoComment({ videoCommentId: comment.id })
  }, [comment.id, handleDeleteVideoComment])

  const handleEditComment = useCallback(
    (value: string | null | undefined) => {
      if (value) {
        handleEditVideoComment({ text: value })
        setOpenEdit(false)
      } else {
        window.alert('メッセージを入力してください')
      }
    },
    [handleEditVideoComment]
  )
  const isSp = useMediaQuery({
    query: '(max-device-width: 500px)'
  })

  return (
    <>
      <Box display="flex" color={Colors.secondary[1000]} width={width}>
        <StyledLink
          routerLink={`/users/${comment.user.id}`}
          href={`/users/${comment.user.id}`}
          style={{ marginRight: isSp ? '6px' : '16px' }}
        >
          <StyledAvatar>
            {!comment.user.iconImagePath ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                backgroundColor="black"
                width="100%"
                height="100%"
              >
                <IonIcon icon={person} style={{ color: 'white', fontSize: '16px' }} />
              </Box>
            ) : (
              <img src={getImageUrl(comment.user.iconImagePath)} alt="avatar" style={{ objectFit: 'cover' }} />
            )}
          </StyledAvatar>
        </StyledLink>

        <Box width="100%">
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" mb="4px">
              <StyledLink
                routerLink={`/users/${comment.user.id}`}
                href={`/users/${comment.user.id}`}
                style={{ marginRight: '16px' }}
              >
                <IonText>
                  <h5 style={{ margin: '0px', fontWeight: 'bold' }}>{comment.user.displayName}</h5>
                </IonText>
              </StyledLink>
              <IonText>
                <h5 style={{ margin: '0px', fontWeight: 'bold', color: Colors.secondary[300] }}>
                  {format(comment.createdAt * 1000, 'yyyy.MM.dd')}
                </h5>
              </IonText>
            </Box>

            {edit && isMine && (
              <Box display="flex">
                <Box mx="8px" cursor="pointer" onClick={() => setOpenEdit(true)}>
                  <IonIcon icon={pencilOutline} style={{ color: Colors.secondary[300] }} />
                </Box>
                <Box mx="8px" cursor="pointer" onClick={() => setOpenDelete(true)}>
                  <IonIcon icon={trashOutline} style={{ color: Colors.secondary[300] }} />
                </Box>
              </Box>
            )}
          </Box>
          <Box mb="8px">
            <IonText>
              <h4 style={{ margin: '0px' }}>{comment.text}</h4>
            </IonText>
          </Box>
        </Box>
      </Box>
      {/* コメント削除モーダル */}
      {edit && (
        <ModalConfirm
          title="コメントを削除します。よろしいですか？"
          confirmLabel="削除"
          onCancel={() => {
            console.log('cancel')
            setOpenDelete(false)
          }}
          onConfirm={handleDeleteComment}
          onDismiss={() => setOpenDelete(false)}
          isOpen={openDelte}
        >
          {
            <Box backgroundColor={Colors.bg.default} borderRadius="12px" p="16px">
              <CommentListItem comment={comment} edit={false} />
            </Box>
          }
        </ModalConfirm>
      )}

      {/* コメント編集モーダル */}
      {edit && (
        <ModalTextEdit
          open={openEdit}
          defaultValue={comment.text}
          onEdited={value => handleEditComment(value)}
          onCancel={() => setOpenEdit(false)}
        />
      )}
    </>
  )
}
