import { Plugins } from '@capacitor/core'
import { IVideo } from '@open-platform/interface/src/entity'
// import { IAddDownloadRequest } from 'capacitor-native-downloader'

import { Filesystem } from '@capacitor/filesystem'

import { useCallback, useEffect, useState } from 'react'
import useSWR, { mutate } from 'swr'
import { SWRCachePath } from '../swr-cache-path'
import { IDownload } from './interface'
import { storage as firebaseStorage } from '@open-platform/firebase-client'
import { isPlatform } from '@ionic/react'
import { Toast } from '@capacitor/toast'
import { CapacitorDataStorageSqlite, capOpenStorageOptions, capStorageOptions } from 'capacitor-data-storage-sqlite'

const storage = CapacitorDataStorageSqlite
const VIDEO_DOWNLOAD_DIR = '/videos'

// stroage settings
const DATABASE_NAME = 'main'
const VIDEO_TABLE_NAME = 'videos'

export const storageOptions: capOpenStorageOptions = {
  database: DATABASE_NAME,
  table: VIDEO_TABLE_NAME
}
export const capStorageOpts: capStorageOptions = {
  database: DATABASE_NAME
}

// ダウンロード済みのものに関して取得することができる

export const useListDownloads = () => {
  const { data, error } = useSWR<IDownload[]>(SWRCachePath.listDownloads(), async () => {
    await storage.openStore(storageOptions)
    const { result: isOpen } = await storage.isStoreOpen(capStorageOpts)
    if (isOpen) {
      const res = await storage.values()
      return res.values.map(value => JSON.parse(value)) as IDownload[]
    } else {
      return []
    }
  })

  return {
    downloadList: data,
    isLoading: !data && !error
  }
}

export type DownloadStatus = 'waiting' | 'downloading' | 'pause' | 'completed' | 'failed'
interface IDownloadInfo {
  status: DownloadStatus
  progress: number
}

export const useDownload = (id: string) => {
  const [error, setError] = useState<Error | undefined>()
  const [targetVideo, setTargetVideo] = useState<IVideo | undefined>()
  const [videoMetaData, setVideoMetaData] = useState<{ size: number } | undefined>()

  useEffect(() => {
    if (!isPlatform('capacitor')) return
    // ダウンロードプログレスのリスナー

    // const progressListener = () => {}
    const progressListener = Plugins.NativeDownloader.addListener(
      'onProgress',
      ({ id: progressId, progress }: { id: string; progress: number }) => {
        if (progressId === id) {
          const donwloadInfo: IDownloadInfo = { status: 'downloading', progress }
          mutate(SWRCachePath.getDownloadInfo(id), donwloadInfo, false)
        }
      }
    )

    // const completeListener = () => {}
    // ダウンロード完了リスナー
    const completeListener = Plugins.NativeDownloader.addListener(
      'onComplete',
      async (props: { id: string; fileUrl: string }) => {
        const { id: taskId, fileUrl } = props
        if (taskId === id) {
          const donwloadInfo: IDownloadInfo = { status: 'completed', progress: 1 }
          if (!targetVideo || !videoMetaData) return
          const data: IDownload = {
            id,
            video: targetVideo,
            filePath: `videos/video-${id}.mp4`,
            fileUri: fileUrl,
            size: videoMetaData.size,
            createdAt: Date.now(),
            updatedAt: Date.now()
          }
          const { result: isOpen } = await storage.isStoreOpen(capStorageOpts)
          if (isOpen) {
            storage.set({ key: id, value: JSON.stringify(data) })
          }
          mutate(SWRCachePath.getDownloadInfo(id), donwloadInfo, false)
          mutate(SWRCachePath.listDownloads())
          await mutate(SWRCachePath.listNativePlayerPlaylist(targetVideo.term.id), undefined, false)
          Toast.show({ text: 'ダウンロードが完了しました' })
        }
      }
    )

    // エラーリスナー
    // ダウンロードに失敗した場合は、プラグイン側で次回起動時にデータは削除処理が走るので、気にしなくて良い
    const errorListener = Plugins.NativeDownloader.addListener('onFailed', ({ id: taskId }: { id: string }) => {
      if (taskId === id) {
        const donwloadInfo: IDownloadInfo = { status: 'failed', progress: 0 }
        mutate(SWRCachePath.getDownloadInfo(id), donwloadInfo)
      }
    })

    // unmount 処理
    return () => {
      if (typeof progressListener === 'function') {
        progressListener()
        completeListener()
        errorListener()
      }
    }
  }, [id, targetVideo, videoMetaData])

  // ダウンロードのステータスやパーセンテージの取得に関しては画面を横断して取得したいので、SWR で管理する
  const { data: downloadStatus } = useSWR<IDownloadInfo | undefined>(
    SWRCachePath.getDownloadInfo(id),
    async () => {
      const { result: isOpen } = await storage.isStoreOpen(capStorageOpts)
      if (isOpen) {
        const { value } = await storage.get({ key: id })
        if (value) {
          return { status: 'completed', progress: 1 }
        }
      }
    },
    {
      fetcher: undefined,
      onError: errr => console.log(errr),
      initialData: { status: 'waiting', progress: 0 }
    }
  )

  const handleCreateDownload = useCallback(
    async (video: IVideo) => {
      try {
        setTargetVideo(video)
        // firestore からメタ情報を取得してくる size を取得しておく
        const metadata = await firebaseStorage.ref(video.downloadPath).getMetadata()
        setVideoMetaData({ size: metadata.size })
        const downloadUrl = await firebaseStorage.ref(video.downloadPath).getDownloadURL()
        const params: any = {
          id,
          filePath: VIDEO_DOWNLOAD_DIR,
          fileName: `video-${id}.mp4`,
          displayName: video.title,
          url: downloadUrl,
          size: metadata.size,
          headers: {
            authorization: ''
          }
        }
        console.log('start===============================')
        await Plugins.NativeDownloader.add({ params: { ...params } })
        console.log('start===============================')
        await Plugins.NativeDownloader.start({ params: { id } })

        const donwloadInfo: IDownloadInfo = { status: 'downloading', progress: 0 }
        mutate(SWRCachePath.getDownloadInfo(id), donwloadInfo, false)
      } catch (error: any) {
        setError(error)
      }
    },
    [id]
  )

  const handlePauseDownload = useCallback(async () => {
    try {
      await Plugins.NativeDownloader.pause({ params: { id } })
      if (downloadStatus) {
        const donwloadInfo: IDownloadInfo = { ...downloadStatus, status: 'pause' }
        mutate(SWRCachePath.getDownloadInfo(id), donwloadInfo, false)
      }
    } catch (error: any) {
      setError(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleResumeDownload = useCallback(async () => {
    try {
      await Plugins.NativeDownloader.resume({ params: { id } })
      if (downloadStatus) {
        const donwloadInfo: IDownloadInfo = { ...downloadStatus, status: 'downloading' }
        mutate(SWRCachePath.getDownloadInfo(id), donwloadInfo, false)
      }
    } catch (error: any) {
      setError(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleCancelDownload = useCallback(async () => {
    try {
      await Plugins.NativeDownloader.cancel({ params: { id } })
      const donwloadInfo: IDownloadInfo = { status: 'waiting', progress: 0 }
      mutate(SWRCachePath.getDownloadInfo(id), donwloadInfo)
    } catch (error: any) {
      setError(error)
    }
  }, [id])

  return {
    downloadStatus,
    error,
    handleCreateDownload,
    handlePauseDownload,
    handleResumeDownload,
    handleCancelDownload
  }
}

export const useDeleteDownload = () => {
  const [error, setError] = useState<Error | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const handleDeleteDownload = useCallback(async (id: string) => {
    try {
      setIsLoading(true)
      const { result: isOpen } = await storage.isStoreOpen(capStorageOpts)
      if (isOpen) {
        const { value } = await storage.get({ key: id })
        if (!value) throw new Error('cannot find data')
        await storage.remove({ key: id })
        const download = JSON.parse(value) as IDownload
        await Filesystem.deleteFile({ path: download.fileUri })
        mutate(SWRCachePath.listDownloads())

        // ダウンロード状況を0にしておく
        const donwloadInfo: IDownloadInfo = { status: 'waiting', progress: 0 }
        mutate(SWRCachePath.getDownloadInfo(id), donwloadInfo, false)
        await mutate(SWRCachePath.listNativePlayerPlaylist(download.video.term.id), undefined, false)
        window.alert('削除が完了しました')
      } else {
        throw new Error('cannot open sqlite storage')
      }
    } catch (error: any) {
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  return {
    error,
    isLoading,
    handleDeleteDownload
  }
}

export const usePlayDownload = () => {
  const [error, setError] = useState<Error | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const handlePlayDownload = useCallback(async (id: string) => {
    try {
      setIsLoading(true)
      const { result: isOpen } = await storage.isStoreOpen(capStorageOpts)
      if (isOpen) {
        const { value } = await storage.get({ key: id })
        if (!value) throw new Error('cannot find data')
        const download = JSON.parse(value) as IDownload
        const nvp = isPlatform('ios') ? Plugins.NvpPlugin : Plugins.Nvp
        nvp.start({ playlist: [{ title: download.video.title, filePath: download.filePath, album: '' }] })
      } else {
        throw new Error('cannot open sqlite storage')
      }
    } catch (error: any) {
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  return {
    error,
    isLoading,
    handlePlayDownload
  }
}
