import React from 'react'
import { Layout } from '../../Layout'
import { PageUserDetail } from '../../organisms'
import { Box, Colors } from '../../atoms'
import { RouteComponentProps, match } from 'react-router'

export interface UserDetailProps extends RouteComponentProps {
  name?: string
}

export const UserDetail = (props: UserDetailProps) => {
  const matcher = props.match as match<{ id: string }>

  return (
    <Layout>
      <Box
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        backgroundColor={Colors.bg.default}
      >
        <PageUserDetail userId={matcher.params.id} />
      </Box>
    </Layout>
  )
}
