import React, { useEffect, useState } from 'react'

import { Box, Colors, Input, Textarea } from '../../atoms'
import { IonAvatar, IonButton, IonText, IonIcon, IonRouterLink, IonLoading, IonAlert } from '@ionic/react'
import { person } from 'ionicons/icons'
import styled from 'styled-components'
import { IUser } from '@open-platform/interface/src/entity'
import { useFormik } from 'formik'
import { useUploadImage } from '../../../modules/utils'
import { add } from 'ionicons/icons'
import { useUpdateMe } from '../../../modules/me'
import { getImageUrl } from '../../../libs'
import { useRef } from 'react'
import { useCallback } from 'react'

const StyledAvatar = styled(IonAvatar)`
  width: 96px;
  height: 96px;
  margin-bottom: 24px;
  border: 1px solid ${Colors.secondary[300]};
  overflow: hidden;
`

const StyledIonButton = styled(IonButton)`
  --background: ${Colors.secondary.default};
  --color: white;
  --border-radius: 12px;
  --box-shadow: none;
`

export interface ProfileEditProps {
  user: IUser
  canEdit?: boolean
  onClickEdit?: () => void
}

interface SendData {
  displayName: string
  profile: string
}

export const ProfileEdit = ({ user, onClickEdit }: ProfileEditProps) => {
  const inputRef = useRef<HTMLInputElement>()
  const { iconImagePath, displayName, profile, email } = user

  const { handleUploadImageFromFileList, fullPath, uploadUrl } = useUploadImage()
  const { handleUpdateMe, isLoading, error } = useUpdateMe()

  const [isPrevLoading, setIsPrevLoading] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)

  const formik = useFormik({
    initialValues: {
      displayName: displayName,
      profile: profile
    },
    validateOnChange: true,
    validateOnBlur: false,
    isInitialValid: true,
    validate: values => {
      const errors: Partial<SendData> = {}
      if (!values.displayName) {
        errors.displayName = '表示名は必須です'
      }
      return errors
    },
    onSubmit: values => {
      const iconImagePath = fullPath
      handleUpdateMe({ profile: values.profile, displayName: values.displayName, iconImagePath })
      setIsPrevLoading(true)
    }
  })

  useEffect(() => {
    if (isPrevLoading && !isLoading && !error) {
      setOpenAlert(true)
      setIsPrevLoading(false)
    }
  }, [isLoading, error, isPrevLoading])

  const getIcon = () => {
    if (!uploadUrl && !iconImagePath) return undefined
    if (uploadUrl) {
      return uploadUrl
    } else {
      return getImageUrl(iconImagePath)
    }
  }

  const handleUploadImage = useCallback(
    (files?: FileList | null) => {
      if (files) {
        handleUploadImageFromFileList(files)
      }
    },
    [handleUploadImageFromFileList]
  )

  return (
    <>
      <Box width="720px" borderRadius="12px" py="100px" px="32px" backgroundColor="white">
        <form
          style={{
            maxWidth: '320px',
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
          onSubmit={formik.handleSubmit}
        >
          <input
            style={{ display: 'none' }}
            ref={inputRef as React.RefObject<HTMLInputElement>}
            type="file"
            accept="image/*"
            onChange={e => handleUploadImage(e.currentTarget.files)}
          />

          <StyledAvatar style={{ width: '96px', height: '96px', marginBottom: '17px' }}>
            <Box
              onClick={() => inputRef.current?.click()}
              cursor="pointer"
              position="relative"
              height="100%"
              overflow="hidden"
            >
              {fullPath || iconImagePath ? (
                <img src={getIcon()} alt="avatar" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="black"
                  width="100%"
                  height="100%"
                >
                  <IonIcon icon={person} style={{ color: 'white', fontSize: '16px' }}></IonIcon>
                </Box>
              )}
              <Box
                position="absolute"
                top={0}
                right={0}
                bottom={0}
                left={0}
                width="100%"
                height="100%"
                backgroundColor="rgba(0,0,0,0.6)"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <IonIcon icon={add} style={{ color: 'white', fontSize: '40px' }} />
              </Box>
            </Box>
          </StyledAvatar>
          <Box width="100%" mb="18px">
            <Box mb="6px">
              <IonText>
                <h6 style={{ color: Colors.secondary[300] }}>メールアドレス</h6>
              </IonText>
            </Box>
            <Box width="100%" backgroundColor="rgba(159, 162, 180, 0.08)" borderRadius="12px" py="9px" px="12px">
              <IonText>
                <h5 style={{ fontSize: '12px', color: Colors.secondary.default }}>{email}</h5>
              </IonText>
            </Box>
          </Box>

          <Box width="100%" mb="18px">
            <Box mb="6px">
              <IonText>
                <h6 style={{ color: Colors.secondary[300] }}>アカウント名</h6>
              </IonText>
            </Box>
            <Box>
              <Input
                placeholder="アカウント名"
                name="displayName"
                onIonChange={formik.handleChange}
                value={formik.values.displayName}
              />
            </Box>
          </Box>

          <Box width="100%" mb="16px">
            <Box mb="6px">
              <IonText>
                <h6 style={{ color: Colors.secondary[300] }}>メッセージ or 一言コメント</h6>
              </IonText>
            </Box>
            <Box>
              <Textarea
                rows={5}
                placeholder="メッセージ or 一言コメント"
                name="profile"
                value={formik.values.profile}
                onIonChange={formik.handleChange}
              />
            </Box>
          </Box>

          <Box mb="32px">
            <IonRouterLink routerLink="/password-change">
              <IonText>
                <div
                  style={{
                    color: Colors.secondary.default,
                    fontSize: '12px',
                    textDecoration: 'underline'
                  }}
                >
                  パスワード変更
                </div>
              </IonText>
            </IonRouterLink>
          </Box>

          <StyledIonButton type="submit" style={{ width: '100%', height: '44px' }} onClick={() => onClickEdit?.()}>
            <IonText>
              <h5 style={{ color: 'white', fontWeight: 'bold', fontSize: '12px' }}>更新する</h5>
            </IonText>
          </StyledIonButton>
        </form>
      </Box>
      <IonLoading isOpen={isLoading} />
      <IonAlert
        mode="ios"
        isOpen={openAlert}
        onDidDismiss={() => setOpenAlert(false)}
        header={'更新が完了しました'}
        buttons={['OK']}
      />
    </>
  )
}
