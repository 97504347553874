import React, { useMemo } from 'react'
import { Box, Colors } from '../../atoms'
import { IVideo } from '@open-platform/interface/src/entity'
import { CourseCard } from '../../molecules'
import { IonText, IonIcon } from '@ionic/react'
import { calendar, eye, star, chevronForwardOutline, chevronBackOutline, bookmark } from 'ionicons/icons'
import { useState } from 'react'
import { convertPeriodToDisplayString } from '../../../libs'
import { SP_MAX_WIDTH } from '../../../setting'
import { useMediaQuery } from 'react-responsive'

export interface courseProps {
  courseList: IVideo[]
  isViewMore?: boolean
  title?: string
  titleIcon?: iconType
  isSort?: boolean
}

export type iconType = 'eye' | 'star' | 'calendar' | 'special'

const getTitleIcon = (icon: iconType) => {
  switch (icon) {
    case 'calendar':
      return calendar
    case 'eye':
      return eye
    case 'star':
      return star
    case 'special':
      return bookmark
  }
}

const getIconColor: { [key in iconType]: string } = {
  eye: '#29CC97',
  star: '#EBC230',
  special: '#DE4346',
  calendar: '#000000'
}

const DEFAULT_DISPLAY_COUNT = 4

export const CourseCardList = ({ courseList, title, titleIcon, isViewMore = false, isSort = true }: courseProps) => {
  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })

  const [isMore, setIsMore] = useState(false)

  const sortedCourseList = useMemo(() => {
    if (isSort) {
      courseList.sort((a: IVideo, b: IVideo): number => {
        return a.sortIndex - b.sortIndex
      })
    }
    return courseList
  }, [courseList, isSort])

  const listCount = useMemo(() => sortedCourseList.length, [sortedCourseList])

  const more = useMemo(() => {
    return isViewMore && !isMore && listCount > 4
  }, [isMore, isViewMore, listCount])

  const handleToggleMore = () => {
    setIsMore(!isMore)
  }

  return (
    <>
      {title && (
        <Box display="flex" alignItems="center" mb={isSp ? '9px' : '21px'}>
          {titleIcon && (
            <Box mr="8px" display="flex" alignItems="center">
              <IonIcon icon={getTitleIcon(titleIcon)} style={{ color: getIconColor[titleIcon], fontSize: '16px' }} />
            </Box>
          )}
          <IonText>
            <h3 style={{ color: Colors.secondary[300], margin: 0 }}>{title}</h3>
          </IonText>
        </Box>
      )}
      <Box display="flex" flexDirection="column" width={isSp ? '100%' : '1200px'}>
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
          overflow="scroll"
          pb={isSp ? '0px' : '16px'}
        >
          {sortedCourseList
            .slice(0, isViewMore ? (isMore ? listCount : DEFAULT_DISPLAY_COUNT) : listCount)
            .map((course, index) => {
              return (
                <Box key={index} mx="0px" mb="16px">
                  <CourseCard
                    href={`/videos/${course.id}`}
                    title={course.title}
                    imageSrc={course.thumbnailPath}
                    index={String(course.sortIndex)}
                    tagName={convertPeriodToDisplayString(course.term.period)}
                  />
                </Box>
              )
            })}
          {[...Array(listCount % 4)].map((_, index) => {
            return <Box key={index} mb={isSp ? '0px' : '16px'} width="267px" flex="0 1 auto" />
          })}
        </Box>
        {more && (
          <Box display="flex" justifyContent="flex-start">
            <Box display="flex" alignItems="center" cursor="pointer" onClick={handleToggleMore}>
              <Box mr="12px">
                <IonText>
                  <h4 style={{ color: Colors.secondary[300], margin: 0 }}>もっと見る</h4>
                </IonText>
              </Box>
              <Box display="flex" alignItems="center">
                <IonIcon icon={chevronForwardOutline} style={{ color: Colors.secondary[300] }} />
              </Box>
            </Box>
          </Box>
        )}

        {!more && isViewMore && listCount > 4 && (
          <Box display="flex" justifyContent="flex-start">
            <Box display="flex" alignItems="center" cursor="pointer" onClick={handleToggleMore}>
              <Box mr="12px">
                <Box display="flex" alignItems="center">
                  <IonIcon icon={chevronBackOutline} style={{ color: Colors.secondary[300] }} />
                  <IonText>
                    <h4 style={{ color: Colors.secondary[300], margin: 0 }}>戻す</h4>
                  </IonText>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}
