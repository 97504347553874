import React from 'react'
import { Box, Colors } from '../../atoms'
import { CourseCardList } from '../CourseCardList'
import { IonText } from '@ionic/react'
import { useGetVideoList } from '../../../modules/video'
import { useGetTerm } from '../../../modules/term'
import { convertPeriodToDisplayString } from '../../../libs'
import { useMediaQuery } from 'react-responsive'
import { SP_MAX_WIDTH } from '../../../setting'

export interface PageSearchProps {
  termId: string
  keyword?: string | null
}

export const PageSearch = ({ termId }: PageSearchProps) => {
  const { videos } = useGetVideoList({ termId })
  const { term } = useGetTerm({ termId })
  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })

  return (
    <Box width="100%" height="100%" maxWidth="1200px" pb="80px">
      <Box mb="46" width="100%" height="100%">
        {videos.length === 0 ? (
          <Box textAlign="center" height="100%" display="flex" alignItems="center" justifyContent="center">
            <IonText>
              <h3 style={{ color: Colors.secondary[300] }}>動画はまだありません</h3>
            </IonText>
          </Box>
        ) : (
          <Box pt={isSp ? '16px' : '100px'} pb="100px" px={isSp ? '16px' : '0px'}>
            <Box mb="16px">
              <IonText>
                {term && (
                  <h3 style={{ color: Colors.secondary.default, margin: 0 }}>
                    {convertPeriodToDisplayString(term.period)}
                  </h3>
                )}
              </IonText>
            </Box>
            <CourseCardList courseList={videos} />
          </Box>
        )}
      </Box>
    </Box>
  )
}
