import { useCallback, useState } from 'react'
import { axios } from '../../libs'
import { IVideoComment } from '@open-platform/interface/src/entity'
import {
  ListVideoCommentsRequest,
  ListVideoCommentsResponse,
  CreateVideoCommentRequest,
  CreateVideoCommentResponse,
  DeleteVideoCommentResponse,
  DeleteVideoCommentRequest,
  UpdateVideoCommentRequest,
  UpdateVideoCommentResponse
} from '@open-platform/interface/src/request'
import useSWR, { mutate } from 'swr'
import { SWRCachePath } from '../swr-cache-path'
import { useToasts } from 'react-toast-notifications'

export const useGetListVideoComment = (query: ListVideoCommentsRequest['query']) => {
  const { data, error } = useSWR<IVideoComment[]>(SWRCachePath.getListVideoComment(query), () =>
    axios
      .get<ListVideoCommentsResponse>('/video_comments', { params: query })
      .then<IVideoComment[]>(res => res.data.videoComments)
  )

  return {
    videoCommentList: data || [],
    error,
    isLoading: !data && !error
  }
}

export const useCreateVideoComment = () => {
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleCreateVideoComment = useCallback(
    async (data: CreateVideoCommentRequest['body']) => {
      if (isLoading) return
      setIsLoading(true)
      setError(undefined)
      try {
        await axios.post<CreateVideoCommentResponse>('/video_comments', data)
        mutate(SWRCachePath.getListVideoComment({ videoId: data.videoId }))
      } catch (err) {
        setError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [isLoading]
  )

  return {
    handleCreateVideoComment,
    error,
    isLoading
  }
}

export const useDeleteVideoComment = ({ videoId }: { videoId: string }) => {
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toast = useToasts()

  const handleDeleteVideoComment = useCallback(
    async (params: DeleteVideoCommentRequest['param']) => {
      if (isLoading) return
      setIsLoading(true)
      setError(undefined)
      try {
        await axios.delete<DeleteVideoCommentResponse>(`/video_comments/${params.videoCommentId}`, { params })
        mutate(SWRCachePath.getListVideoComment({ videoId }))
        toast.addToast('コメントを削除しました', { appearance: 'success', autoDismiss: true })
      } catch (err) {
        setError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [isLoading, toast, videoId]
  )

  return {
    handleDeleteVideoComment,
    error,
    isLoading
  }
}

export const useEditVideoComment = ({ videoCommentId, videoId }: { videoCommentId: string; videoId: string }) => {
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleEditVideoComment = useCallback(
    async (params: UpdateVideoCommentRequest['body']) => {
      if (isLoading) return
      setIsLoading(true)
      setError(undefined)
      try {
        await axios.patch<UpdateVideoCommentResponse>(`/video_comments/${videoCommentId}`, params)
        mutate(SWRCachePath.getVideoComment({ videoCommentId }))
        mutate(SWRCachePath.getListVideoComment({ videoId }))
      } catch (err) {
        console.log(err)
        setError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [isLoading, videoCommentId, videoId]
  )

  return {
    handleEditVideoComment,
    error,
    isLoading
  }
}
