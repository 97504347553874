import React from 'react'
import { Layout } from '../../Layout'
import { PageSearch } from '../../organisms'
import { Box } from '../../atoms'
import { RouteComponentProps } from 'react-router'

export interface SearchProps extends RouteComponentProps {
  name?: string
}

export const Search = ({ location }: SearchProps) => {
  const searchParams = new URLSearchParams(location.search)
  const termId = searchParams.get('termId') as string | undefined
  const keyword = searchParams.get('keyword')
  return (
    <Layout termId={termId}>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%" overflow="scroll">
        {termId && <PageSearch termId={termId} keyword={keyword} />}
      </Box>
    </Layout>
  )
}
