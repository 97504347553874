import { axios } from '../../libs'
import useSWR from 'swr'
import { ListSchedulesRequest, ListSchedulesResponse } from '@open-platform/interface/src/request'
import { SWRCachePath } from '../swr-cache-path'

export const useGetListSchedule = ({ page = 1, perPage = 100 }: ListSchedulesRequest['query']) => {
  const { data, error } = useSWR<ListSchedulesResponse>(SWRCachePath.getListSchedule({ page, perPage }), () => {
    return axios.get<ListSchedulesResponse>(`/schedules?page=${page}&perPage=${perPage}`).then(res => res.data)
  })
  return {
    listSchedule: data?.schedules,
    pagination: data?.pagination,
    error,
    isLoading: !data && !error
  }
}

// export const useGetSchedule = ({ scheduleId }: AdminGetScheduleRequest['param']) => {
//   const { data, error } = useSWR<ISchedule>(SWRCachePath.getSchedule({ scheduleID: scheduleId }), () => {
//     return axios
//       .get<AdminGetScheduleResponse>(`/admin/schedules/${scheduleId}`)
//       .then<ISchedule>(res => res.data.schedule)
//   })
//   return {
//     schedule: data,
//     error,
//     isLoading: !data && !error
//   }
// }
