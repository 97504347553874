import { DependencyList, useEffect, useMemo, useState } from 'react'
import { isPlatform } from '@ionic/core/components'
import { Network } from '@capacitor/network'

export const convertPeriodToDisplayString = (period: string) => {
  if (period === '999999') return '特別動画「BASIC」'

  return `${period.slice(0, 4)}年${period.slice(4, 6)}月`
}

export const getImageUrl = (path: string) => {
  return process.env.REACT_APP_GCS_URL + path
}

export const execCopy = (value: string) => {
  // 空div 生成
  var tmp = document.createElement('div')
  // 選択用のタグ生成
  var pre = document.createElement('pre')

  // 親要素のCSSで user-select: none だとコピーできないので書き換える
  pre.style.webkitUserSelect = 'auto'
  pre.style.userSelect = 'auto'

  tmp.appendChild(pre).textContent = value

  // 要素を画面外へ
  var s = tmp.style
  s.position = 'fixed'
  s.right = '200%'

  // body に追加
  document.body.appendChild(tmp)
  // 要素を選択
  document.getSelection()?.selectAllChildren(tmp)

  // クリップボードにコピー
  var result = document.execCommand('copy')

  // 要素削除
  document.body.removeChild(tmp)

  return result
}

export const isCapacitorIos = () => {
  return isPlatform('capacitor') && isPlatform('ios')
}

export const useSafeAreaInset = (position: 'top' | 'bottom' | 'right' | 'left') => {
  const value = useMemo(() => {
    var isSafeAreaConst = CSS.supports('padding-bottom: constant(safe-area-inset-bottom)') // iOS11.3未満
    var isSafeAreaEnv = CSS.supports('padding-bottom: env(safe-area-inset-bottom)') // iOS11.3以降

    //- iOSでかつ、上記のconst, envに対応してる場合
    if (isCapacitorIos() && (isSafeAreaConst || isSafeAreaEnv)) {
      //safe-area-inset-bottomを設定
      var elmDiv = document.createElement('div')

      if (isSafeAreaConst) {
        elmDiv.style.height = 'constant(safe-area-inset-' + position + ')'
      } else {
        elmDiv.style.height = 'env(safe-area-inset-' + position + ')'
      }

      //非表示で追加
      elmDiv.style.display = 'none'
      document.body.appendChild(elmDiv)

      //safe-area-inset-bottomの値を取得
      var elmDivStyle = window.getComputedStyle(elmDiv)
      var safeAreaInset = parseInt(elmDivStyle.height)

      //ダミー削除
      document.body.removeChild(elmDiv)

      return safeAreaInset
    } else {
      return 0
    }
  }, [position])

  return { value }
}

export function useEffectAsync(effect: () => void, deps?: DependencyList): void {
  useEffect(() => {
    effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export const useNetwork = () => {
  const [isOnline, setIsOnline] = useState(false)

  useEffect(() => {
    if (!isPlatform('capacitor')) {
      return setIsOnline(true)
    }
    Network.addListener('networkStatusChange', (status: any) => {
      console.log('Network status changed', status)
      console.log('changegehgfege')
      setIsOnline(status.connected)
    })
    Network.getStatus().then((status: any) => {
      setIsOnline(status.connected)
    })
  }, [])

  return { isOnline }
}
