import React from 'react'
import { Layout } from '../../Layout'
import { Box, Colors } from '../../atoms'
import { RouteComponentProps } from 'react-router'

import { useMediaQuery } from 'react-responsive'
import { PageSpecifiedCommercialTransaction } from '../../organisms'

export interface SpecifiedCommercialTransactionProps extends RouteComponentProps {
  name?: string
}

export const SpecifiedCommercialTransaction = (_: SpecifiedCommercialTransactionProps) => {
  const isSp = useMediaQuery({
    query: '(max-device-width: 500px)'
  })
  return (
    <Layout disableTermSelectbox>
      <Box
        mx="auto"
        backgroundColor={Colors.bg.default}
        display="flex"
        justifyContent="center"
        py={isSp ? '0px' : '80px'}
      >
        <PageSpecifiedCommercialTransaction />
      </Box>
    </Layout>
  )
}
