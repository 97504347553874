import React from 'react'

import { IonIcon, IonModal, IonRippleEffect, IonText } from '@ionic/react'
import { ISchedule } from '@open-platform/interface/src/entity'
import { Box, Colors } from '../../atoms'
import { format } from 'date-fns'
import { Markdown } from '../../atoms/MarkdownParser'
import { close } from 'ionicons/icons'
import './Modal.css'
import styled from 'styled-components'

export interface EventModalProps {
  schedule: ISchedule | undefined
  onClose?: () => void
}

const kindMap = {
  seminar: 'オンラインセミナー',
  event: 'イベント'
}

const StyledModal = styled(IonModal)`
  --width: 300px;
  --min-height: 300px;
`

const StyledMarkdown = styled.div`
  > * {
    color: ${Colors.secondary.default};
  }

  > p {
    font-size: 12px;
    white-space: pre-wrap;
    word-break: break-all;
    margin: 12px 0px;
    line-height: 1.2;
  }
  > p a {
    color: ${Colors.secondary.default};
  }
`

export const EventModal = ({ schedule, onClose }: EventModalProps) => {
  return (
    <StyledModal cssClass="modal-custom-class" isOpen={Boolean(schedule)} onDidDismiss={() => onClose?.()}>
      {schedule && (
        <Box position="relative" backgroundColor="white" p="32px">
          <Box>
            <IonText>
              <h3 style={{ color: Colors.secondary.default, margin: 0 }}>
                {format(schedule.startDatetime * 1000, 'yyyy.MM.dd MMM')}
              </h3>
            </IonText>
          </Box>
          <Box mb="12px">
            <IonText>
              <h2 style={{ fontWeight: 'bold', color: Colors.secondary.default, margin: 0 }}>
                {format(schedule.startDatetime * 1000, 'HH:mm')} - {format(schedule.endDatetime * 1000, 'HH:mm')}
              </h2>
            </IonText>
          </Box>
          <Box mb="12px">
            <IonText>
              <Box display="flex" alignItems="center">
                <Box width="6px" height="6px" borderRadius="2px" backgroundColor={Colors.secondary.default} mr="2px" />
                <h6 style={{ color: Colors.secondary.default, margin: 0 }}>{kindMap[schedule.kind]}</h6>
              </Box>
            </IonText>
          </Box>
          <Box display="block" mb="16px">
            <Box border={`1px solid ${Colors.secondary[300]}`} borderRadius="6px" px="8px" py="4px" maxWidth="200px">
              <IonText>
                <h5 style={{ color: Colors.secondary[300], margin: 0 }}>{schedule.title}</h5>
              </IonText>
            </Box>
          </Box>
          <Box mb="12px">
            <IonText>
              <StyledMarkdown>
                <Markdown content={schedule.description} />
              </StyledMarkdown>
            </IonText>
          </Box>
          <Box position="absolute" top="0" right="0" p="16px" cursor="pointer" onClick={() => onClose?.()}>
            <IonRippleEffect />
            <IonIcon icon={close} style={{ color: Colors.secondary.default, fontSize: '24px' }} />
          </Box>
        </Box>
      )}
    </StyledModal>
  )
}
