import React from 'react'
import { Box } from '../../atoms'
import { IonLoading } from '@ionic/react'
import { useMediaQuery } from 'react-responsive'
import { SP_MAX_WIDTH } from '../../../setting'
import { useGetAnnouncement } from '../../../modules/announcement'
import { NotificationItem } from '../../molecules/NotificationItem'

export interface PageAnnouncementDetailProps {
  announcementId: string
}

export const PageAnnouncementDetail = ({ announcementId }: PageAnnouncementDetailProps) => {
  const { announcement } = useGetAnnouncement({ announcementId })

  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })

  return announcement ? (
    <Box maxWidth="100%" pb="80px">
      <NotificationItem announcement={announcement} defaultOpen />
    </Box>
  ) : (
    <IonLoading isOpen={true} />
  )
}
