import React from 'react'
import { Layout } from '../../Layout'
import { PageNotification } from '../../organisms'
import { Box, Colors } from '../../atoms'
import { RouteComponentProps } from 'react-router'
import { useMediaQuery } from 'react-responsive'

export interface NotificationProps extends RouteComponentProps {
  name?: string
}

export const Notification = (_: NotificationProps) => {
  const isSp = useMediaQuery({
    query: '(max-device-width: 500px)'
  })
  return (
    <Layout>
      <Box
        minHeight="100%"
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        backgroundColor={isSp ? 'white' : Colors.bg.default}
      >
        <PageNotification />
      </Box>
    </Layout>
  )
}
