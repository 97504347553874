import { ListVideoCommentsRequest } from '@open-platform/interface/src/request'

export const SWRCachePath = {
  ME: `/me`,
  VIDEO: '/vidoes/',
  VIDEO_LIST: '/vidoes?termId=',
  VIDEO_LATEST: '/videos/latest',
  TERM: '/terms',
  TERM_LIST: '/terms/',
  VIDEO_PLAY_HISTORY_LIST: '/video_play_history_list',
  VIDEO_FAVORITE_LIST: '/video_favorite_list',
  VIDEO_COMMENT_LIST: '/video_comment_list',
  USER: '/user/',
  getVideo: ({ videoId }: { videoId: string }) => {
    return `/videos/${videoId}`
  },
  getListAnnouncement: ({ page = 1, perPage = 12 }: { page?: number; perPage: number }) => {
    return `/announcements?page=${page}&perPage${perPage}`
  },
  getAnnouncement: ({ announcementId }: { announcementId: string }) => {
    return `/announcements/${announcementId}`
  },
  getListVideoComment: ({ videoId, page, perPage }: ListVideoCommentsRequest['query']) => {
    return `video_comments?videoId=${videoId}` + (page ? `&page=${page}` : '') + (perPage ? `&perPage=${perPage}` : '')
  },
  getListSchedule: ({ page = 1, perPage = 100 }: { page?: number; perPage?: number }) => {
    return `list_schedules?page=${page}&perPage=${perPage}`
  },
  getVideoComment: ({ videoCommentId }: { videoCommentId: string }) => {
    return `/video_comments/${videoCommentId}`
  },
  getVideoFavorite: ({ videoId }: { videoId: string }) => {
    return `/video_favorites?video_id=${videoId}`
  },
  // ダウンロード
  getDownloadInfo: (id: string) => {
    return `/downloadInfo/${id}`
  },

  listDownloads: () => {
    return `/downloads`
  },

  listNativePlayerPlaylist: (termId: string) => {
    return `/listNativePlayerPlaylist?termiId=${termId}`
  }
} as const
