import React, { useState } from 'react'
import { Box, Colors, Input } from '../../atoms'
import { IonButton, IonText, IonAlert } from '@ionic/react'

import styled from 'styled-components'
import { IUser } from '@open-platform/interface/src/entity'
import { useFormik } from 'formik'
import { useChangePassword } from '../../../modules/auth'

const StyledIonButton = styled(IonButton)`
  --background: ${Colors.secondary.default};
  --color: white;
  --border-radius: 12px;
  --box-shadow: none;
`

export interface PasswordChangeProps {
  user: IUser
  canEdit?: boolean
  onClickEdit?: () => void
}

interface SendData {
  oldPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

export const PasswordChange = ({ user, onClickEdit }: PasswordChangeProps) => {
  const { email } = user
  // const [isPrevLoading, setIsPrevLoading] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const { handlePasswordChange } = useChangePassword()

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: ''
    },
    validateOnChange: true,
    validateOnBlur: false,
    isInitialValid: true,
    validate: values => {
      const errors: Partial<SendData> = {}
      if (!values.oldPassword) {
        errors.oldPassword = '古いパスワードは必須項目です'
      }
      if (!values.newPassword) {
        errors.newPassword = '古いパスワードは必須項目です'
      }
      if (!values.newPasswordConfirmation) {
        errors.newPasswordConfirmation = '必須項目です'
      }

      if (values.newPassword !== values.newPasswordConfirmation) {
        errors.newPassword = 'パスワードが一致しません'
        errors.newPasswordConfirmation = 'パスワードが一致しません'
      }

      return errors
    },
    onSubmit: ({ newPassword, oldPassword }) => {
      handlePasswordChange({
        email,
        newPassword,
        oldPassword
      })
    }
  })

  return (
    <>
      <Box width="720px" borderRadius="12px" py="100px" px="32px" backgroundColor="white">
        <form
          style={{
            maxWidth: '320px',
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
          onSubmit={formik.handleSubmit}
        >
          <Box mb="64px" textAlign="center">
            <IonText>
              <h3 style={{ fontWeight: 'bold', margin: 0, color: Colors.secondary.default, fontSize: '16px' }}>
                パスワード再設定
              </h3>
            </IonText>
          </Box>
          <Box width="100%" mb="16px">
            <Box mb="6px">
              <IonText>
                <h6 style={{ color: Colors.secondary[300] }}>メールアドレス</h6>
              </IonText>
            </Box>
            <Box width="100%" backgroundColor="rgba(159, 162, 180, 0.08)" borderRadius="12px" py="9px" px="12px">
              <IonText>
                <h5 style={{ fontSize: '12px', color: Colors.secondary.default }}>{email}</h5>
              </IonText>
            </Box>
          </Box>

          <Box width="100%" mb="16px">
            <Box mb="6px">
              <IonText>
                <h6 style={{ color: Colors.secondary[300] }}>古いパスワード</h6>
              </IonText>
            </Box>
            <Box>
              <Input
                placeholder="パスワード"
                name="oldPassword"
                type="password"
                onIonChange={formik.handleChange}
                value={formik.values.oldPassword}
              />
            </Box>
          </Box>

          <Box width="100%" mb="16px">
            <Box mb="6px">
              <IonText>
                <h6 style={{ color: Colors.secondary[300] }}>新しいパスワード</h6>
              </IonText>
            </Box>
            <Box>
              <Input
                placeholder="パスワード"
                name="newPassword"
                type="password"
                onIonChange={formik.handleChange}
                value={formik.values.newPassword}
              />
            </Box>
          </Box>
          <Box width="100%" mb="32px">
            <Box mb="6px">
              <IonText>
                <h6 style={{ color: Colors.secondary[300] }}>新しいパスワードの再入力</h6>
              </IonText>
            </Box>
            <Box>
              <Input
                placeholder="パスワード"
                name="newPasswordConfirmation"
                type="password"
                onIonChange={formik.handleChange}
                value={formik.values.newPasswordConfirmation}
              />
            </Box>
          </Box>

          <StyledIonButton type="submit" style={{ width: '100%', height: '44px' }} onClick={() => onClickEdit?.()}>
            <IonText>
              <h5 style={{ color: 'white', fontWeight: 'bold', fontSize: '12px' }}>パスワード再設定</h5>
            </IonText>
          </StyledIonButton>
        </form>
      </Box>
      {/* <IonLoading isOpen={isLoading} /> */}
      <IonAlert
        mode="ios"
        isOpen={openAlert}
        onDidDismiss={() => setOpenAlert(false)}
        header={'パスワードの変更が完了しました'}
        buttons={['OK']}
      />
    </>
  )
}
