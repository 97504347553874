import { useState } from 'react'
import { auth, firebase } from '@open-platform/firebase-client'
import { useIonRouter } from '@ionic/react'
import { axios } from '../../libs'
import { useToasts } from 'react-toast-notifications'
import { useNotificaitonRegister } from '../notification'

const wait = (ms: number) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(null)
    }, ms)
  })
}
export const useSignIn = () => {
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const router = useIonRouter()
  const toast = useToasts()
  const { handleNotificationRegister } = useNotificaitonRegister()

  const handleSignInWithEmailAndPassword = async (param: { email: string; password: string }) => {
    if (isLoading) return
    setIsLoading(true)
    setError(undefined)
    try {
      console.log('start===============================================')
      const { user } = await auth.signInWithEmailAndPassword(param.email, param.password)
      console.log('end===============================================')
      if (!user) {
        setError(new Error('user not found'))
      } else {
        // devic token を登録するようにする
        handleNotificationRegister()

        await axios.post('/me', {})
        router.push('/', 'forward', undefined, { unmount: true })
        wait(128)
        window.location.reload()
        toast.addToast('ログインが完了しました', { appearance: 'success', autoDismiss: true })
      }
    } catch (err: any) {
      console.log(err.toString())
      auth.signOut()
      setError(err)
      console.log(err.message)
      toast.addToast('IDかパスワードが間違っています', { appearance: 'error', autoDismiss: true })
    } finally {
      setIsLoading(false)
    }
  }

  return { handleSignInWithEmailAndPassword, isLoading, error }
}

export const useSignOut = () => {
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const handleSignOut = async () => {
    if (isLoading) return
    setIsLoading(true)
    setError(undefined)
    try {
      auth.signOut().then(() => setIsLoading(false))
    } catch (err: any) {
      setIsLoading(false)
      setError(err)
    }
  }

  return { handleSignOut, isLoading, error }
}

export const usePasswordReset = () => {
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handlePasswordReset = async (email: string) => {
    setIsLoading(true)
    try {
      await auth.sendPasswordResetEmail(email)
      setIsLoading(false)
    } catch (error: any) {
      setError(error)
      setIsLoading(false)
    }
  }
  return {
    error,
    isLoading,
    handlePasswordReset
  }
}

export const useChangePassword = () => {
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toast = useToasts()

  const handlePasswordChange = async ({
    email,
    oldPassword,
    newPassword
  }: {
    email: string
    oldPassword: string
    newPassword: string
  }) => {
    setIsLoading(true)
    try {
      const { currentUser } = auth
      if (currentUser) {
        const credential = firebase.auth.EmailAuthProvider.credential(email, oldPassword)
        await currentUser.reauthenticateWithCredential(credential)
        await currentUser.updatePassword(newPassword)
        toast.addToast('パスワードの変更が完了しました', { appearance: 'success', autoDismiss: true })
      } else {
        throw Error('ログインしてください')
      }
      setIsLoading(false)
    } catch (error: any) {
      toast.addToast('パスワードの変更に失敗しました', { appearance: 'success', autoDismiss: true })
      setError(error)
      setIsLoading(false)
    }
  }
  return {
    error,
    isLoading,
    handlePasswordChange
  }
}
