import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useGetListAnnouncement } from '../../../modules/announcement'
import { SP_MAX_WIDTH } from '../../../setting'
import { Box } from '../../atoms'
import { NotificationItem } from '../../molecules/NotificationItem'

export interface PageNotificationhProps {}

export const PageNotification = (_: PageNotificationhProps) => {
  const { listAnnouncement = [] } = useGetListAnnouncement({ page: 1, perPage: 1000 })
  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })

  return (
    <Box width="100%" maxWidth="720px" py="80px" backgroundColor={isSp ? 'white' : 'inherit'}>
      {listAnnouncement.map((item, index) => (
        <Box mb="24px" key={index} px={isSp ? '16px' : '0px'}>
          <NotificationItem announcement={item} />
        </Box>
      ))}
    </Box>
  )
}
