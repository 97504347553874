import React from 'react'
import { Layout } from '../../Layout'
import { PageVideoSingle } from '../../organisms'
import { Box } from '../../atoms'
import { RouteComponentProps, match } from 'react-router'

export interface VideoDetailProps extends RouteComponentProps {
  name?: string
}

export const VideoDetail = (props: VideoDetailProps) => {
  const matcher = props.match as match<{ id: string }>

  return (
    <Layout back>
      <Box display="flex" justifyContent="center">
        <PageVideoSingle videoId={matcher.params.id} />
      </Box>
    </Layout>
  )
}
