import { useState } from 'react'
import { storage, auth } from '@open-platform/firebase-client'
// import { useIonRouter } from '@ionic/react'
// import { axios } from '../../libs'

const getInputImage = (): Promise<FileList> => {
  return new Promise(resolve => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'image/*'
    input.onchange = (e: Event) => {
      const target = e.target as HTMLInputElement
      if (target.files && target.files.length > 0) {
        resolve(target.files)
      }
    }
    input.click()
  })
}

export const useUploadImage = () => {
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [uploadUrl, setUrl] = useState<string | undefined>(undefined)
  const [fullPath, setFullPath] = useState<string | undefined>(undefined)
  const handleUploadImageFromFileList = async (fileList: FileList) => {
    const file = fileList[0]
    setError(undefined)
    setIsLoading(true)
    const uid = auth.currentUser?.uid
    if (!uid) {
      setIsLoading(false)
      setError(new Error(`can't get firebase uid`))
      return
    }
    try {
      const { ref } = await storage.ref('user_icons').child(uid).child(file.name).put(file)
      const url = await ref.getDownloadURL()
      setFullPath(ref.fullPath)
      setUrl(url)
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }
  const handleUploadImage = async () => {
    const fileList = await getInputImage()
    const file = fileList[0]
    setError(undefined)
    setIsLoading(true)
    const uid = auth.currentUser?.uid
    if (!uid) {
      setIsLoading(false)
      setError(new Error(`can't get firebase uid`))
      return
    }
    try {
      const { ref } = await storage.ref('user_icons').child(uid).child(file.name).put(file)
      const url = await ref.getDownloadURL()
      setFullPath(ref.fullPath)
      setUrl(url)
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  return { handleUploadImage, handleUploadImageFromFileList, isLoading, uploadUrl, fullPath, error }
}

export const useDownloadFile = () => {
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleDownloadFile = ({ fileName, url }: { fileName: string; url: string }) => {
    var xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    setIsLoading(true)
    xhr.onload = function () {
      var blob = xhr.response
      var blobUrl = window.URL.createObjectURL(
        new Blob([blob], {
          type: blob.type
        })
      )
      const a = document.createElement('a') as HTMLAnchorElement
      a.href = blobUrl
      a.download = fileName
      setIsLoading(false)
      a.click()
    }
    xhr.onerror = () => {
      setError(xhr.response)
    }
    xhr.open('GET', url)
    xhr.send()
  }

  return {
    error,
    isLoading,
    handleDownloadFile
  }
}
