import React from 'react'

import { Box, Colors } from '../../atoms'
import { PagePasswordReset } from '../../organisms'
import { Layout } from '../../Layout'
import { useMediaQuery } from 'react-responsive'
import { SP_MAX_WIDTH } from '../../../setting'

export const PasswordReset = () => {
  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })
  return (
    <Layout disableTermSelectbox={true}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor={isSp ? 'white' : Colors.bg.default}
      >
        <PagePasswordReset />
      </Box>
    </Layout>
  )
}
