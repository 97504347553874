/* eslint-disable no-irregular-whitespace */
import React from 'react'
import styled from 'styled-components'
import { Box, Colors } from '../../atoms'
import { Markdown } from '../../atoms/MarkdownParser'

export interface PageSpecifiedCommercialTransactionProps {}

const StyledMarkdown = styled.div`
  > * {
    color: ${Colors.secondary.default};
  }
  > h1 {
    font-size: 40px;
  }
  > h2 {
    font-size: 26px;
  }
  > h3 {
    font-size: 20px;
    font-weight: bold;
  }

  > p {
    /* display: flex; */
    font-size: 14px;
    white-space: pre-wrap;
    word-break: break-all;
    margin: 12px 0px;
    line-height: 1.2;
  }
  > p img {
    max-width: 200px;
    object-fit: cover;
    cursor: pointer;
  }
  > p a {
    color: ${Colors.primary.default};
  }
  > blockquote {
    color: ${Colors.secondary[300]};
    padding: 0px;
    padding-left: 12px;
    margin: 0px;
    border-left: 3px solid ${Colors.secondary[300]};
    word-break: break-all;
    white-space: pre-wrap;
  }

  > p code {
    display: inline-block;
    align-items: center;
    padding: 1px 8px;
    margin: 0px 2px;
    background-color: black;
    font-size: 12px;
    color: white;
  }

  ul {
    margin-left: -20px;
    margin-bottom: 8px;
  }
`

const content = `
### 販売業者       
株式会社 オープンプラットフォーム
代表取締役 池田貴将
本社 東京都中央区銀座1-15-13-402

### お問合わせ
電話：03-6264-4956
FAX：03-6264-4957

メールアドレス： info@ikedatakamasa.com
受付対応：月曜日〜金曜日 10時〜17時まで

URL： [http://www.ikedatakamasa.jp](http://www.ikedatakamasa.jp)

### 会員解約について

〈クレジットカード払〉
退会のお申し出がない限り、毎月自動更新です。解約をご希望される際は上記お問い合わせまでご連絡をお願い致します。
〈銀行振込〉
半年後に更新のお手続きが必要になります。自動更新ではありません。
※途中で解約を希望される場合は、上記お問い合わせまでご連絡をお願い致します。サービスをお届けしていない月の会員費はご返金処理をさせて頂きます。
`

export const PageSpecifiedCommercialTransaction = (_: PageSpecifiedCommercialTransactionProps) => {
  return (
    <Box width="100%" maxWidth="720px" pb="80px" backgroundColor="white" borderRadius="12px" py="102px" px="40px">
      <Box>
        <h2
          style={{
            fontSize: '24px',
            color: Colors.secondary.default,
            margin: 0,
            marginBottom: '24px',
            textAlign: 'center'
          }}
        >
          特定商取引
        </h2>
        <StyledMarkdown>
          <Markdown content={content} />
        </StyledMarkdown>
      </Box>
    </Box>
  )
}
