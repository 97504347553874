import React, { useEffect, useState } from 'react'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Route } from 'react-router-dom'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'
import './App.css'

/* Theme variables */
import './theme/variables.css'
import './theme/typography.css'
import { Auth } from './pages/Auth'
import { Menu } from './components/molecules'

import {
  VideoDetail,
  Search,
  Login,
  PasswordReset,
  MyPage,
  UserDetail,
  Schedule,
  TermsOfService,
  Notification,
  PasswordChange,
  SpecifiedCommercialTransaction,
  SpecialVideo,
  DownloadList,
  Home,
  AnnouncementDetail
} from './components/pages'
import { MyPageEdit } from './components/pages/MyPageEdit'
import { useMediaQuery } from 'react-responsive'
import { Tabbar } from './components/molecules/Tabbar'
import { ToastProvider } from 'react-toast-notifications'
import { Toast } from './components/atoms'
import { useNotification } from './modules/notification'
import { useRegisterApplicationListner } from './modules/application/hooks'

const App: React.FC = () => {
  const isSp = useMediaQuery({
    query: '(max-device-width: 500px)'
  })
  const [mountMenu, setMenu] = useState(false)
  useNotification()
  useRegisterApplicationListner()
  useEffect(() => {
    setTimeout(() => {
      setMenu(true)
    }, 256)
  })

  return (
    <ToastProvider components={{ Toast: Toast }} autoDismissTimeout={2000} autoDismiss>
      <IonApp>
        <IonReactRouter>
          {mountMenu && <Menu />}

          <IonRouterOutlet>
            <Route path="/login" component={Login} exact />
            <Route path="/term-of-service" component={TermsOfService} exact />
            <Route path="/password-reset" component={PasswordReset} exact />
            <Route path="/specified-commercial-transaction" component={SpecifiedCommercialTransaction} exact />
            <Auth>
              <Route path="/" component={Home} exact />
              <Route path="/videos/:id" component={VideoDetail} exact />
              <Route path="/announcements/:id" component={AnnouncementDetail} exact />

              <Route path="/downloads" component={DownloadList} exact />
              <Route path="/search" component={Search} exact />
              <Route path="/mypage" component={MyPage} exact />
              <Route path="/users/:id" component={UserDetail} exact />
              <Route path="/mypage-edit" component={MyPageEdit} exact />
              <Route path="/schedule" component={Schedule} exact />

              <Route path="/notification" component={Notification} exact />
              <Route path="/password-change" component={PasswordChange} exact />
              <Route path="/special-movie" component={SpecialVideo} exact />
            </Auth>
          </IonRouterOutlet>

          {isSp && <Tabbar />}
        </IonReactRouter>
      </IonApp>
    </ToastProvider>
  )
}

export default App
