import React, { useMemo } from 'react'
import { Box } from '../../atoms'
import { IVideo } from '@open-platform/interface/src/entity'
import { CourseListItem } from '../../molecules'
import { convertPeriodToDisplayString } from '../../../libs'

export interface courseProps {
  courseList: IVideo[]
  currentId: string
}

export const Playlist = ({ courseList, currentId }: courseProps) => {
  //- sortIndex 順に並び替え
  const sortedCourseList = useMemo(() => {
    courseList.sort((a: IVideo, b: IVideo): number => {
      return a.sortIndex - b.sortIndex
    })
    return courseList
  }, [courseList])

  return (
    <Box color="black">
      {sortedCourseList.map((course, i) => {
        return (
          <Box key={i} mb="4px">
            <CourseListItem
              id={course.id}
              title={course.title}
              index={course.sortIndex}
              tagName={convertPeriodToDisplayString(course.term.period)}
              active={currentId === course.id}
            />
          </Box>
        )
      })}
    </Box>
  )
}
