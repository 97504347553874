import { IonTextarea } from '@ionic/react'
import styled from 'styled-components'
import { Colors } from '..'

export const Textarea = styled(IonTextarea)`
  font-size: 12px;
  color: ${Colors.secondary.default};
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid ${Colors.secondary[200]};
  padding-right: 8px !important;
  padding-left: 8px !important;
`
