import React from 'react'

import { Box, Colors } from '../../atoms'
import { CommentListItem } from '../../molecules'
import { IVideoComment } from '@open-platform/interface/src/entity'
import { useGetListVideoComment } from '../../../modules/videoComment'
import { useMediaQuery } from 'react-responsive'
import { SP_MAX_WIDTH } from '../../../setting'

export interface commentProps {
  videoId: string
  commentList?: IVideoComment[]
}

export const CommentList = ({ videoId }: commentProps) => {
  const { videoCommentList } = useGetListVideoComment({ videoId })
  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })

  return (
    <Box maxHeight={isSp ? '256px' : '480px'} display="flex" flexDirection="column">
      <Box mb="24px" color={Colors.secondary[300]} flex="0 1 auto">
        {videoCommentList.length}件のコメント
      </Box>
      <Box overflowY="scroll" height="100%">
        {videoCommentList.length === 0 ? (
          <Box
            display="flex"
            textAlign="center"
            color={Colors.secondary[300]}
            height="100%"
            justifyContent="center"
            alignItems="center"
            pb="30px"
          >
            コメントはありません
          </Box>
        ) : (
          videoCommentList.map((comment, i) => {
            return (
              <Box key={i} mb="24px">
                <CommentListItem comment={comment} />
              </Box>
            )
          })
        )}
      </Box>
    </Box>
  )
}
