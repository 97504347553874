import React from 'react'
import { Profile } from '../Profile'
import { useGetUser } from '../../../modules/user'
import { IonLoading } from '@ionic/react'

export interface PageUserDetailProps {
  userId: string
}
export const PageUserDetail = ({ userId }: PageUserDetailProps) => {
  const { user } = useGetUser({ userID: userId })
  return user ? <Profile user={user} canEdit={false} /> : <IonLoading isOpen={true} />
}
