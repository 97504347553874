import React, { useState, useMemo } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { Button } from '../../atoms/Button'
import { Box, Colors } from '../../atoms'
import styled from 'styled-components'
import { IonText, IonIcon } from '@ionic/react'
import { chevronForwardOutline, chevronBackOutline, downloadOutline } from 'ionicons/icons'
import { useDownloadFile } from '../../../modules/utils'
import { IVideo } from '@open-platform/interface/src/entity'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

type Size = number | string
export interface PdfViewerProps {
  width?: Size
  height?: Size
  url?: string
  video?: IVideo
  onClickDownload?: () => void
}

const StyledPage = styled(Page)`
  height: ${({ video }: PdfViewerProps) => (video?.term.textbookOrientation === 'LANDSCAPE' ? '30vh' : '60vh')};
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    pointer-events: none;
  }
  .react-pdf__Page__textContent {
    display: none !important;
  }
  &:hover {
    cursor: pointer;
  }
`

export const PdfViewer = ({ width, url, onClickDownload, video }: PdfViewerProps) => {
  const file = useMemo(() => ({ url }), [url])
  const { handleDownloadFile } = useDownloadFile()
  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [isHover, setIsHover] = useState(false)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }
  const handleMouseOver = () => {
    !isHover && setIsHover(true)
  }
  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const isDisabledForward = () => {
    return pageNumber >= numPages
  }

  const isDisabledBack = () => {
    return pageNumber <= 1
  }

  const handleClickBack = () => {
    if (!isDisabledBack()) {
      setPageNumber(pageNumber - 1)
    }
  }
  const handleClickForward = () => {
    if (!isDisabledForward()) {
      setPageNumber(pageNumber + 1)
    }
  }
  const handleClickDownload = () => {
    onClickDownload?.()
    console.log({ url })
    if (url) {
      handleDownloadFile({ fileName: `${video?.term?.textbookName || video?.title || '講座'}.pdf`, url })
    }
  }

  return (
    <Box
      width={width || '368px'}
      minWidth={width || '368px'}
      // height={height || '100vh'}
      display="flex"
      flexDirection="column"
      boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
      borderRadius="12px"
      p="24px"
    >
      <Box
        width="100%"
        position="relative"
        border={`1px solid ${Colors.secondary[200]}`}
        mb="9px"
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <Document
          options={{
            cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true
          }}
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <StyledPage pageNumber={pageNumber} />
        </Document>
        {
          <Box
            position="absolute"
            bottom="0px"
            display="flex"
            transition="256ms"
            opacity={isHover ? '100' : '0'}
            justifyContent="center"
            width="100%"
            pb="16px"
          >
            <Box
              display="flex"
              alignItems="center"
              backgroundColor="white"
              p="8px"
              borderRadius="12px"
              boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
            >
              <Button disabled={isDisabledBack()} onClick={handleClickBack}>
                <IonIcon icon={chevronBackOutline} />
              </Button>
              <Box mx="8px" width="44px" display="flex" alignItems="center" justifyContent="center">
                <IonText>
                  <h5 style={{ color: Colors.secondary[1000] }}>
                    {pageNumber} / {numPages}
                  </h5>
                </IonText>
              </Box>
              <Button disabled={isDisabledForward()} onClick={handleClickForward}>
                <IonIcon icon={chevronForwardOutline} />
              </Button>
            </Box>
          </Box>
        }
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={handleClickDownload}>
          <Box mr="7px">
            <IonIcon icon={downloadOutline} style={{ fontSize: '20px' }} />
          </Box>
          PDF
        </Button>
      </Box>
    </Box>
  )
}
