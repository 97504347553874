import { useState } from 'react'
import { axios } from '../../libs'
import { IVideoPlayHistory } from '@open-platform/interface/src/entity'
import {
  CreateVideoPlayHistoryRequest,
  ListVideoPlayHistoriesRequest,
  ListVideoPlayHistoriesResponse,
  CreateVideoPlayHistoryResponse
} from '@open-platform/interface/src/request'
import useSWR, { mutate } from 'swr'
import { SWRCachePath } from '../swr-cache-path'

export const useGetListVideoPlayHistory = (query: ListVideoPlayHistoriesRequest['query']) => {
  const { data, error } = useSWR<IVideoPlayHistory[]>(SWRCachePath.VIDEO_PLAY_HISTORY_LIST, () =>
    axios
      .get<ListVideoPlayHistoriesResponse>('/video_play_histories', { params: query })
      .then<IVideoPlayHistory[]>(res => res.data.videoPlayHistories)
  )
  return {
    videoPlayHistoryList: data || [],
    error,
    isLoading: !data && !error
  }
}

export const useCreateVideoPlayHistory = () => {
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleCreateVideoHistory = async (data: CreateVideoPlayHistoryRequest['body']) => {
    if (isLoading) return
    setIsLoading(true)
    setError(undefined)
    try {
      await axios.post<CreateVideoPlayHistoryResponse>('/video_play_histories', data)
      setIsLoading(false)
      mutate(SWRCachePath.VIDEO_PLAY_HISTORY_LIST)
    } catch (err) {
      setIsLoading(false)
      setError(err)
    }
  }

  return {
    handleCreateVideoHistory,
    error,
    isLoading
  }
}
