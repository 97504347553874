import { IonSelect, IonSelectOption } from '@ionic/react'
import React from 'react'
import styled from 'styled-components'
import './modal.css'

export interface PlaybackRateSelect {
  value?: number
  onSelect?: (value: number) => void
}

const items = [
  { value: 0.5, label: 'x 0.5 ' },
  { value: 0.75, label: 'x 0.75 ' },
  { value: 1.0, label: 'x 1.0 ' },
  { value: 1.25, label: 'x 1.25 ' },
  { value: 1.5, label: 'x 1.5 ' },
  { value: 1.75, label: 'x 1.75 ' },
  { value: 2.0, label: 'x 2.0 ' }
]

const StyledSelect = styled(IonSelect)`
  --padding-start: 20px;
  --padding-end: 12px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  transition: 256ms;
  z-index: 999;
  &:hover {
    background-color: rgba(0, 0, 0, 1);
  }
`

export const PlaybackRateSelect = ({ value = 1.0, onSelect }: PlaybackRateSelect) => {
  return (
    <StyledSelect
      interface="popover"
      value={value}
      className="playback"
      placeholder="再生スピードを選択してください"
      onIonChange={e => {
        onSelect?.(e.detail.value)
      }}
    >
      {items.map(item => {
        return (
          <IonSelectOption key={item.value} value={item.value}>
            {item.label}
          </IonSelectOption>
        )
      })}
    </StyledSelect>
  )
}
