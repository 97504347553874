import React, { useState } from 'react'
import { IonCard, IonCardContent, IonInput, IonButton, IonText, IonRouterLink } from '@ionic/react'
import styled from 'styled-components'
import { Box, Colors } from '../../atoms'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { usePasswordReset } from '../../../modules/auth'

const StyledCard = styled(IonCard)`
  --background: white;
  border-radius: 12px;
  box-shadow: none;
  min-width: 720px;
  padding: 100px 200px;
`

const StyledInput = styled(IonInput)`
  border: 1px solid #eaeaea;
  border-radius: 2px;
  font-size: 10px;
  color: ${Colors.secondary.default};
  height: 36px;
  border-radius: 12px;
  overflow: hidden;
`

const StyledIonButton = styled(IonButton)`
  --background: ${Colors.secondary.default};
  --color: white;
  --border-radius: 12px;
`

interface SendData {
  email: string
}

export const PagePasswordReset = () => {
  const [isSend, setIsSend] = useState(false)
  const { handlePasswordReset } = usePasswordReset()

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validateOnChange: true,
    validateOnBlur: false,
    validate: values => {
      const errors: Partial<SendData> = {}
      if (!values.email) {
        errors.email = 'メールアドレスは必須です'
      }
      return errors
    },
    onSubmit: ({ email }) => {
      if (email) {
        setIsSend(true)
        handlePasswordReset(email)
      }
    }
  })

  const { email } = formik.values

  useEffect(() => {
    setIsSend(false)
    formik.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledCard>
      <IonCardContent>
        <form onSubmit={formik.handleSubmit}>
          <Box textAlign="center" mb="32px">
            <IonText>
              <h3 style={{ color: Colors.secondary.default, margin: 0, fontWeight: 'bold' }}>パスワード再設定</h3>
            </IonText>
          </Box>
          {!isSend ? (
            <Box>
              <Box textAlign="center" mb="16px">
                <IonText>
                  <h5 style={{ color: Colors.secondary.default }}>登録されたメールアドレス宛に</h5>
                  <h5 style={{ color: Colors.secondary.default }}>パスワード再設定のURLを送信します。</h5>
                </IonText>
              </Box>
              <Box mb="32px">
                <StyledInput
                  name="email"
                  type="email"
                  placeholder="メールアドレス"
                  required
                  value={email}
                  onIonChange={formik.handleChange}
                />
              </Box>
              <Box mt="16px" display="flex" justifyContent="center">
                <Box width="100%" mb="16px">
                  <StyledIonButton type="submit" disabled={!formik.isValid} style={{ width: '100%', height: '44px' }}>
                    <IonText>
                      <h5 style={{ color: 'white', fontWeight: 'bold' }}>送信する</h5>
                    </IonText>
                  </StyledIonButton>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box mb="32px">
              <Box textAlign="center" mb="32px">
                <IonText>
                  <h2 style={{ fontWeight: 'bold', margin: 0, color: Colors.teritary.default, fontSize: '20px' }}>
                    送信完了
                  </h2>
                </IonText>
              </Box>
              <Box textAlign="center">
                <IonText>
                  <h4 style={{ margin: 0, color: Colors.secondary.default }}>{email}に</h4>
                  <h4 style={{ margin: 0, color: Colors.secondary.default }}>パスワード再設定のURLを送信しました。</h4>
                </IonText>
              </Box>
            </Box>
          )}

          <Box textAlign="center">
            <IonRouterLink
              routerLink="/login"
              routerDirection="forward"
              onClick={() => {
                formik.resetForm()
                setIsSend(false)
              }}
            >
              <IonText>
                <h5 style={{ color: Colors.secondary.default }}>ログインへ戻る</h5>
              </IonText>
            </IonRouterLink>
          </Box>
        </form>
      </IonCardContent>
    </StyledCard>
  )
}
