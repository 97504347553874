import React from 'react'
import { IonPage, IonContent } from '@ionic/react'
import { Header, Footer } from '../molecules'
import { useMediaQuery } from 'react-responsive'
import { useNetwork } from '../../libs'
import { Offline } from '../pages'

export interface LayoutProps {
  title?: string
  termId?: string
  disabledFooter?: boolean
  disableTermSelectbox?: boolean
  isDisplayOnOfline?: boolean
  back?: boolean
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  title,
  disabledFooter = false,
  termId,
  disableTermSelectbox = false,
  isDisplayOnOfline = false,
  back = false
}) => {
  const isSp = useMediaQuery({
    query: '(max-device-width: 500px)'
  })
  const { isOnline } = useNetwork()

  return (
    <IonPage>
      <Header title={title} termId={termId} disableTermSelectBox={disableTermSelectbox} back={back} height={54} />

      {isOnline || isDisplayOnOfline ? (
        <IonContent fullscreen>{children}</IonContent>
      ) : (
        <IonContent fullscreen>
          <Offline />
        </IonContent>
      )}
      {!isSp && !disabledFooter && <Footer height={'52px'} />}
    </IonPage>
  )
}
