import React from 'react'
import { IonCard, IonCardContent, IonButton, IonText, IonRouterLink, IonLoading } from '@ionic/react'
import styled from 'styled-components'
import { Box, Colors, Input } from '../../atoms'
import { useSignIn } from '../../../modules/auth'
import { useFormik } from 'formik'
import { useMediaQuery } from 'react-responsive'

const StyledCard = styled(IonCard)`
  --background: white;
  border-radius: 12px;
  box-shadow: none;
  min-width: 720px;
  padding: 100px 196px;
`

const StyledIonButton = styled(IonButton)`
  --background: ${Colors.secondary.default};
  --color: white;
  --border-radius: 12px;
`

interface SendData {
  email: string
  password: string
}

export const Login = () => {
  const { isLoading, handleSignInWithEmailAndPassword } = useSignIn()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnChange: true,
    validateOnBlur: false,
    validate: values => {
      const errors: Partial<SendData> = {}
      if (!values.email) {
        errors.email = 'メールアドレスは必須です'
      }
      if (!values.password) {
        errors.password = 'パスワードは必須です'
      }
      return errors
    },
    onSubmit: values => {
      if (values.email && values.password) {
        handleSignInWithEmailAndPassword({ email: values.email.toString(), password: values.password.toString() })
      }
    }
  })
  const isSp = useMediaQuery({
    query: '(max-device-width: 500px)'
  })

  const { email, password } = formik.values

  return (
    <>
      <IonLoading isOpen={isLoading} />
      <StyledCard>
        {isSp && (
          <Box mb="55px">
            <IonText color="primary">
              <h1 style={{ textAlign: 'center', fontSize: '22px' }}>Change the World College</h1>
            </IonText>
          </Box>
        )}
        <IonCardContent style={{ padding: '0px' }}>
          <form onSubmit={formik.handleSubmit}>
            <Box mb="32px">
              <Input
                name="email"
                type="email"
                placeholder="メールアドレス"
                required
                value={email}
                onIonChange={formik.handleChange}
              />
              <Box height={'16px'} />
              <Input
                name="password"
                type="password"
                placeholder="パスワード"
                required
                value={password}
                onIonChange={formik.handleChange}
              />
            </Box>

            <Box mt="16px" display="flex" justifyContent="center">
              <Box width="100%" mb="16px">
                <StyledIonButton
                  type="submit"
                  disabled={isLoading || !formik.isValid}
                  style={{ width: '100%', height: '44px' }}
                >
                  <IonText>
                    <h5 style={{ color: 'white', fontWeight: 'bold' }}>ログイン</h5>
                  </IonText>
                </StyledIonButton>
              </Box>
            </Box>
            <Box textAlign="center">
              <IonRouterLink routerLink="/password-reset" routerDirection="forward">
                <IonText>
                  <h5 style={{ color: Colors.secondary.default }}>パスワードを忘れた方へ</h5>
                </IonText>
              </IonRouterLink>
            </Box>
          </form>
          {isSp && (
            <Box display="flex" mb="100px" mt="16px">
              <IonRouterLink
                routerLink="term-of-service"
                routerDirection="forward"
                color="success"
                style={{
                  textDecoration: 'underline',
                  textAlign: 'center',
                  width: '100%',
                  fontSize: '12px',
                  lineHeight: '18px'
                }}
              >
                利用規約
              </IonRouterLink>
            </Box>
          )}
        </IonCardContent>
      </StyledCard>
    </>
  )
}
