import React from 'react'

import { Box } from '../../atoms'
import { useListDownloads } from '../../../modules/download/hooks'

import { IonText } from '@ionic/react'
import { DownloadListRow } from './DownloadListRow'
import { Layout } from '../../Layout'

export const DownloadList = () => {
  const { downloadList } = useListDownloads()
  return (
    <Layout isDisplayOnOfline>
      <Box py="40px">
        <IonText color="secondary">
          <h3 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 700, margin: 0, lineHeight: 1.5 }}>
            ダウンロードした動画
          </h3>
        </IonText>
      </Box>
      <Box px="16px">
        {downloadList?.map((item, index) => {
          return (
            <Box key={index} mb="16px">
              <DownloadListRow download={item} shadow />
            </Box>
          )
        })}

        <Box width="100%" height="100px" />
      </Box>
    </Layout>
  )
}
