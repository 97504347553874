var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
if (!firebase.apps.length) {
    firebase.initializeApp(require('../firebase.client.key.json'));
}
var auth = firebase.auth();
var storage = firebase.storage();
var FirebaseAuthenticationError = /** @class */ (function (_super) {
    __extends(FirebaseAuthenticationError, _super);
    function FirebaseAuthenticationError(error) {
        var _newTarget = this.constructor;
        var _this = _super.call(this, error.message) || this;
        _this.name = _newTarget.name;
        Object.setPrototypeOf(_this, _newTarget.prototype);
        // https://github.com/firebase/firebase-js-sdk/blob/master/packages/auth/src/error_auth.js
        switch (error.code) {
            case 'auth/email-already-in-use':
                _this.message = '入力されたメールアドレスはすでに使用されています。';
                break;
            case 'auth/invalid-email':
                _this.message = '不正なメールアドレスです';
                break;
            case 'auth/user-not-found':
                _this.message = 'ユーザーが見つかりませんでした';
                break;
            case 'auth/wrong-password':
                _this.message = 'パスワードが一致しません';
                break;
            default:
                _this.message = 'エラーが発生しました';
                break;
        }
        return _this;
    }
    return FirebaseAuthenticationError;
}(Error));
export { firebase, auth, FirebaseAuthenticationError, storage };
