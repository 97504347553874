import { IVideo } from '@open-platform/interface/src/entity'
import React, { useCallback } from 'react'
import { Plugins } from '@capacitor/core'
import { Box, Colors } from '../../atoms'
import { IonIcon, IonRippleEffect, isPlatform } from '@ionic/react'
import { playCircleSharp } from 'ionicons/icons'
import { useGetCurrentTermVideoPlayList } from '../../../modules/video'

export interface NativeVideoPlayerProps {
  video: IVideo
}

export const NativeVideoPlayer = ({ video }: NativeVideoPlayerProps) => {
  const { playlist } = useGetCurrentTermVideoPlayList({ termId: video.term.id })
  const handleStartVideo = useCallback(() => {
    const nvp = isPlatform('ios') ? Plugins.NvpPlugin : Plugins.Nvp
    const idx = playlist.findIndex(item => item.id === video.id)
    if (idx === -1) return
    const list = playlist.slice(idx)
    nvp.start({
      playlist: list
    })
  }, [playlist, video])

  return (
    <Box width="100%" height="100%">
      <Box
        className="ion-activatable"
        width="100%"
        height="100%"
        onClick={() => handleStartVideo()}
        position="relative"
        overflow="hidden"
      >
        <img
          alt="img"
          style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'relative' }}
          src={process.env.REACT_APP_GCS_URL + video.thumbnailPath}
        />
        <Box
          width="100%"
          height="100%"
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          top="0"
          right="0"
          bottom="0"
          left="0"
        >
          <Box zIndex={999}>
            <Box
              width="80px"
              height="80px"
              borderRadius="50%"
              backgroundColor={Colors.primary.default}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <IonIcon icon={playCircleSharp} style={{ fontSize: '80px', color: 'white' }} />
            </Box>
          </Box>
          <IonRippleEffect />
        </Box>
      </Box>
    </Box>
  )
}
