import React from 'react'
import ReactMarkdown from 'react-markdown'
import { MarkdownItemImage } from './MarkdownItemImage'
import { MarkdownItemLink } from './MarkdownItemLink'

interface Props {
  content: string
}

export const Markdown = ({ content }: Props) => {
  return (
    <ReactMarkdown
      source={content}
      escapeHtml={false}
      renderers={{
        image: MarkdownItemImage,
        link: MarkdownItemLink
      }}
    />
  )
}
