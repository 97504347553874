import React, { useEffect, useMemo } from 'react'
import { Box, Colors } from '../../atoms'
import { IonAvatar, IonButton, IonText, IonIcon, useIonRouter, isPlatform } from '@ionic/react'
import { person } from 'ionicons/icons'
import styled from 'styled-components'
import { IUser } from '@open-platform/interface/src/entity'
import { getImageUrl } from '../../../libs'
import { ModalConfirm } from '..'
import { useState } from 'react'
import { useSignOut } from '../../../modules/auth'
import { App, AppInfo } from '@capacitor/app'

const Line = styled.div`
  width: 100%;
  background-color: ${Colors.secondary[200]};
  height: 2px;
`

const StyledAvatar = styled(IonAvatar)`
  width: 96px;
  height: 96px;
  margin-bottom: 24px;
  border: 1px solid ${Colors.secondary[300]};
  overflow: hidden;
`

const StyledIonButton = styled(IonButton)`
  --background: ${Colors.secondary.default};
  --color: white;
  --border-radius: 12px;
`

export interface ProfileProps {
  user: IUser
  canEdit?: boolean
  onClickEdit?: () => void
}

export const Profile = ({ user, canEdit = false }: ProfileProps) => {
  const { iconImagePath, displayName, profile } = user
  const router = useIonRouter()
  const [openLogout, setOpenLogout] = useState(false)
  const { handleSignOut } = useSignOut()
  const [info, setInfo] = useState<AppInfo | undefined>(undefined)
  const isCap = useMemo(() => isPlatform('capacitor'), [])

  useEffect(() => {
    if (isPlatform('capacitor')) {
      App.getInfo().then(info => {
        console.log(info)
        setInfo(info)
      })
    }
  }, [])

  return (
    <Box width="720px" borderRadius="12px" py="100px" px="32px" backgroundColor="white">
      <Box maxWidth="320px" mx="auto" display="flex" alignItems="center" flexDirection="column">
        <StyledAvatar>
          {iconImagePath ? (
            <img src={getImageUrl(iconImagePath)} alt="avatar" />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              backgroundColor="black"
              width="100%"
              height="100%"
            >
              <IonIcon icon={person} style={{ color: 'white', fontSize: '16px' }}></IonIcon>
            </Box>
          )}
        </StyledAvatar>
        <Box width="100%" mb="24px">
          <IonText>
            <h6 style={{ margin: '0px 10px 8px', color: Colors.secondary[300] }}>アカウント名</h6>
          </IonText>
          <Line />
          <IonText>
            <h5 style={{ margin: '8px 10px 10px', color: Colors.secondary.default, fontWeight: 'bold' }}>
              {displayName}
            </h5>
          </IonText>
        </Box>
        <Box width="100%" mb="24px">
          <IonText>
            <h6 style={{ margin: '0px 10px 8px', color: Colors.secondary[300] }}>メッセージ or 一言コメント</h6>
          </IonText>
          <Line />
          <IonText>
            <h5
              style={{
                margin: '8px 10px 10px',
                color: Colors.secondary.default,
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all'
              }}
            >
              {profile}
            </h5>
          </IonText>
        </Box>
        {canEdit && (
          <>
            <StyledIonButton
              type="submit"
              style={{ width: '100%', height: '44px' }}
              onClick={() => router.push('/mypage-edit', 'none')}
            >
              <IonText>
                <h5 style={{ color: 'white', fontWeight: 'bold', fontSize: '12px' }}>編集する</h5>
              </IonText>
            </StyledIonButton>

            <Box onClick={() => setOpenLogout(true)} color={Colors.secondary[600]} mt="24px" cursor="pointer">
              <div style={{ fontSize: '12px', textDecoration: 'underline' }}>ログアウト</div>
            </Box>

            <ModalConfirm
              title="ログアウトしますか？"
              confirmLabel="OK"
              onCancel={() => {
                console.log('cancel')
                setOpenLogout(false)
              }}
              onConfirm={() => handleSignOut()}
              onDismiss={() => setOpenLogout(false)}
              isOpen={openLogout}
            />
          </>
        )}

        {isCap && (
          <Box width="100%" display="flex" justifyContent="center" mt="16px" color="black">
            アプリバージョン: v{info?.version}({info?.build})
          </Box>
        )}
      </Box>
    </Box>
  )
}
