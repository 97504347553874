import React, { useState, useEffect, useMemo } from 'react'
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  useIonRouter,
  IonIcon,
  IonRippleEffect,
  IonRouterLink
} from '@ionic/react'

import { menuController } from '@ionic/core'

import { chevronBack } from 'ionicons/icons'
import styled from 'styled-components'
import { Box, SelectBox } from '../../atoms'
import { useGetTermList } from '../../../modules/term'
import { convertPeriodToDisplayString, useSafeAreaInset } from '../../../libs/utils'
import { useMediaQuery } from 'react-responsive'
import { SP_MAX_WIDTH } from '../../../setting'

type Size = number | string
export interface HeaderProps {
  width?: Size
  height?: number
  zIndex?: number
  title?: string
  termId?: string
  disableTermSelectBox?: boolean
  back?: boolean
}

const StyledToolBar = styled(IonToolbar)`
  --background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
`

const StyledIonTitle = styled(IonTitle)`
  text-align: center;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
`

const StyledHeader = styled(IonHeader)`
  .header-md::after {
    background-image: none;
  }
`

export const Header = ({
  width = '100%',
  height = 64,
  zIndex = 999,
  title = 'Change the World College',
  disableTermSelectBox = false,
  termId,
  back = false
}: HeaderProps) => {
  const [value, setValue] = useState<string | undefined>(termId)
  const router = useIonRouter()
  const { terms = [] } = useGetTermList()
  const { value: topInset } = useSafeAreaInset('top')
  const options = useMemo(
    () => terms.map(item => ({ label: convertPeriodToDisplayString(item.period), value: item.id })),
    [terms]
  )

  const [mountMenu, setMenu] = useState(false)

  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })

  const handleSelected = (value: string) => {
    setValue(value)
    router.push(`/search?termId=${value}`, 'forward', undefined, { unmount: true })
  }

  useEffect(() => {
    setValue(termId)
  }, [termId])

  const canBack = useMemo(() => {
    return router.canGoBack() && back && isSp
  }, [router, back, isSp])

  useEffect(() => {
    const checkEnable = async () => {
      const enable = await menuController.isEnabled('main-content')
      if (!enable) {
        setTimeout(() => {
          checkEnable()
        }, 128)
        return
      }
      setMenu(true)
    }
    checkEnable()
  }, [])

  return (
    <>
      <StyledHeader mode="md" style={{ width, height: `${height + topInset}px`, zIndex }}>
        <StyledToolBar style={{ height: `${height + topInset}px`, backgroundColor: 'white', position: 'relative' }}>
          {canBack && (
            <Box
              color="black"
              width="50px"
              height="50px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              className="ion-activatable"
              overflow="hidden"
              position="relative"
              onClick={() => router.goBack()}
            >
              <IonIcon icon={chevronBack} style={{ fontSize: '18px' }} />
              <IonRippleEffect />
            </Box>
          )}

          {mountMenu && !isSp && (
            <IonButtons slot="end" style={{ height: `${height + topInset}px` }}>
              <IonMenuButton
                color="primary"
                menu="main-content"
                autoHide={false}
                onClick={async () => {
                  await menuController.isEnabled()
                  menuController.open('main-content')
                }}
              />
            </IonButtons>
          )}

          {!canBack && (
            <StyledIonTitle
              color="primary"
              size="large"
              style={{
                fontFamily: "'SF Pro Display','Hiragino Kaku Gothic ProN','メイリオ',sans-serif",
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                fontSize: isSp ? '14px' : '20px',
                textAlign: isSp ? 'left' : 'center'
              }}
            >
              <IonRouterLink routerLink={'/'} routerOptions={{ unmount: true }}>
                {title}
              </IonRouterLink>
            </StyledIonTitle>
          )}
          {!disableTermSelectBox && (
            <Box
              position="absolute"
              top="0"
              right={isSp ? '16px' : '48px'}
              height="100%"
              display="flex"
              alignItems="center"
            >
              <SelectBox placeholder="タームを選択する" options={options} value={value} onSelected={handleSelected} />
            </Box>
          )}
        </StyledToolBar>
      </StyledHeader>
    </>
  )
}
