import React from 'react'
import { Layout } from '../../Layout'
import { PageAnnouncementDetail } from '../../organisms'
import { Box, Colors } from '../../atoms'
import { RouteComponentProps, match } from 'react-router'
import { useMediaQuery } from 'react-responsive'

export interface AnnouncementDetailProps extends RouteComponentProps {
  name?: string
}

export const AnnouncementDetail = (props: AnnouncementDetailProps) => {
  const matcher = props.match as match<{ id: string }>
  const isSp = useMediaQuery({
    query: '(max-device-width: 500px)'
  })
  return (
    <Layout>
      <Box
        minHeight="100%"
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        backgroundColor={isSp ? 'white' : Colors.bg.default}
      >
        <PageAnnouncementDetail announcementId={matcher.params.id} />
      </Box>
    </Layout>
  )
}
