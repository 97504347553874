import { IonModal, IonButton, IonIcon, IonText, IonRippleEffect } from '@ionic/react'
import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { Box, Colors } from '../../atoms'
import styled from 'styled-components'
import { close } from 'ionicons/icons'
import './custom.css'
import { DownloadListRow } from '../../pages/DownloadList/DownloadListRow'
import { IDownload } from '../../../modules/download/interface'
// import { execCopy } from '../../../libs'
// import { Plugins } from '@capacitor/core'
import { useDeleteDownload } from '../../../modules/download/hooks'
// const { Share } = Plugins

export interface DownloadVideoDetailModalProps {
  open: boolean
  onClose: () => void
  download: IDownload
}

const StyledIonButton = styled(IonButton)`
  width: 50px;
  height: 50px;
  --box-shadow: none;
  --background: transparent;
  --color: black;
  --border-radius: 50%;
  --padding-start: 0px;
  --padding-end: 0px;
`

const MenuItem = ({
  title,
  onClick,
  isDanger = false
}: {
  title: string
  onClick?: () => void
  isDanger?: boolean
}) => {
  return (
    <div
      className="ion-activatable ripple-parent"
      onClick={e => {
        e.stopPropagation()
        onClick?.()
      }}
      style={{ position: 'relative', marginBottom: '8px', overflow: 'hidden' }}
    >
      <Box px="16px" py="12px" backgroundColor="#F5F6F8" borderRadius="12px">
        <IonText>
          <h3 style={{ fontWeight: 'normal', color: isDanger ? Colors.primary.default : '#black', margin: 0 }}>
            {title}
          </h3>
        </IonText>
        <IonRippleEffect />
      </Box>
    </div>
  )
}

export const DownloadVideoDetailModal = ({ open, onClose, download }: DownloadVideoDetailModalProps) => {
  const modalRef = useRef<HTMLIonModalElement>(null)
  const { handleDeleteDownload } = useDeleteDownload()

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.onclick = () => {
        console.log('aaaaaa')
      }
    }
  }, [])

  return (
    <IonModal
      backdropDismiss
      swipeToClose
      ref={modalRef}
      mode="ios"
      isOpen={open}
      cssClass="donwload-video-modal"
      onDidDismiss={onClose}
    >
      <Box position="relative" color="black" onClick={() => modalRef.current?.dismiss()}>
        <Box position="absolute" right="0px" top="0px">
          <StyledIonButton
            size="small"
            onClick={e => {
              e.stopPropagation()
              onClose()
            }}
          >
            <IonIcon icon={close} style={{ color: '#BABAC7', fontSize: '18px' }} />
          </StyledIonButton>
        </Box>
        <Box pointerEvents="none">
          <DownloadListRow download={download} hideMenu />
        </Box>

        <Box width="100%" height="0.5px" backgroundColor="rgba(60, 60, 67, 0.36)" />
        <Box p="16px">
          {/* <MenuItem
            title="リンクをコピー"
            onClick={() => {
              execCopy(shareUrl)
              window.alert('コピーが完了しました')
            }}
          />
          <MenuItem
            title="共有する"
            onClick={() => {
              Share.share({
                title: download.video.title,
                text: '',
                url: shareUrl,
                dialogTitle: `${download.video.title} を共有する`
              })
            }}
          /> */}
          <MenuItem
            title="削除"
            isDanger
            onClick={() => {
              if (window.confirm('削除しますか？')) handleDeleteDownload(download.id)
            }}
          />
        </Box>
      </Box>
    </IonModal>
  )
}
