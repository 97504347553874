import { axios } from '../../libs'
import { ITerm } from '@open-platform/interface/src/entity'
import useSWR from 'swr'
import { ListTermsResponse, GetTermRequest } from '@open-platform/interface/src/request'
import { SWRCachePath } from '../swr-cache-path'

export const useGetTermList = () => {
  const { data, error } = useSWR<ITerm[]>(SWRCachePath.TERM_LIST, () =>
    axios.get<ListTermsResponse>('/terms').then<ITerm[]>(res => {
      const data = res.data
      return data.terms
    })
  )
  return {
    terms: data,
    error,
    isLoading: !data && !error
  }
}

export const useGetTerm = ({ termId }: GetTermRequest['param']) => {
  const { data, error } = useSWR<ITerm>(SWRCachePath.TERM + termId, () =>
    axios.get(`/terms/${termId}`).then(res => {
      const data = res.data
      return data.term
    })
  )
  return {
    term: data,
    error,
    isLoading: !data && !error
  }
}
