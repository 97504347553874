import React from 'react'

import { IonText } from '@ionic/react'
import { Box, Colors } from '../../atoms'
import { CourseCardList } from '../CourseCardList'
import { useGetSpecialVideoList } from '../../../modules/video'
import { useMediaQuery } from 'react-responsive'
import { SP_MAX_WIDTH } from '../../../setting'

export const PageSpecialVideo = () => {
  const { videos = [] } = useGetSpecialVideoList()

  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })

  return (
    <Box width="100%" maxWidth="1200px" pb="80px">
      <Box mb="46" width="100%">
        {videos.length === 0 ? (
          <Box textAlign="center">
            <IonText>
              <h3 style={{ color: Colors.secondary[300] }}>動画はまだありません</h3>
            </IonText>
          </Box>
        ) : (
          <Box pt={isSp ? '208px' : '100px'} px={isSp ? '16px' : '0px'}>
            <Box mb="16px">
              <IonText>
                <h3 style={{ color: Colors.secondary.default, margin: 0 }}>特典動画</h3>
              </IonText>
            </Box>
            <CourseCardList courseList={videos} />
          </Box>
        )}
      </Box>
    </Box>
  )
}
