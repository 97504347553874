import React from 'react'
import { IonToolbar, IonTitle, IonFooter } from '@ionic/react'
import styled from 'styled-components'
import { Colors } from '../../atoms'

type Size = number | string
export interface FooterProps {
  width?: Size
  height?: Size
  zIndex?: number
  title?: string
}

const StyledToolBar = styled(IonToolbar)`
  --background: ${Colors.primary.default};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
`

const StyledIonTitle = styled(IonTitle)`
  text-align: center;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
`

const StyledFooter = styled(IonFooter)`
  .header-md::after {
    background-image: none;
  }
`

export const Footer = ({ width = '100%', height = '96px', zIndex = 999 }: FooterProps) => {
  return (
    <StyledFooter className="ion-no-border" style={{ width, height, zIndex }}>
      <StyledToolBar style={{ width, height }}>
        <StyledIonTitle style={{ height }}>Copyright © OpenPlatform Inc. All Rights</StyledIonTitle>
      </StyledToolBar>
    </StyledFooter>
  )
}
