import React from 'react'

import { Layout } from '../../Layout'
import { PageSpecialVideo } from '../../organisms'
import { Box } from '../../atoms'
import { RouteComponentProps } from 'react-router'

export interface SpecialTermProps extends RouteComponentProps {
  name?: string
}

export const SpecialVideo = (_: SpecialTermProps) => {
  return (
    <Layout>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%" overflow="scroll">
        <PageSpecialVideo />
      </Box>
    </Layout>
  )
}
