import React, { useCallback } from 'react'
import { Box } from '../../atoms'
import { ellipsisHorizontal } from 'ionicons/icons'
import { IonButton, IonIcon, IonRippleEffect, IonText, isPlatform } from '@ionic/react'
import styled from 'styled-components'
import { DownloadVideoDetailModal } from '../../organisms'
import { useState } from 'react'
import { IDownload } from '../../../modules/download/interface'
import { Plugins } from '@capacitor/core'
import { format } from 'date-fns'
import { useListDownloads } from '../../../modules/download/hooks'

export interface DownloadListRowProps {
  shadow?: boolean
  hideMenu?: boolean
  download: IDownload
}

const StyledIonButton = styled(IonButton)`
  width: 40px;
  height: 40px;
  --box-shadow: none;
  --background: transparent;
  --color: black;
  --border-radius: 50%;
  --padding-start: 0px;
  --padding-end: 0px;
`
export const DownloadListRow = ({ shadow = false, hideMenu = false, download }: DownloadListRowProps) => {
  const [open, setOpen] = useState(false)
  const { downloadList } = useListDownloads()

  const handleStartVideo = useCallback(() => {
    const nvp = isPlatform('ios') ? Plugins.NvpPlugin : Plugins.Nvp
    const idx = downloadList?.findIndex(d => d.id === download.id)
    if (idx === -1) return
    const list = downloadList?.slice(idx)
    nvp.start({
      playlist: list?.map(item => ({ title: item.video.title, filePath: item.filePath, album: '' })) || []
    })
  }, [download.id, downloadList])

  return (
    <>
      {!hideMenu && <DownloadVideoDetailModal download={download} open={open} onClose={() => setOpen(false)} />}
      <div
        className="ion-activatable ripple-parent"
        style={{
          position: 'relative',
          overflow: 'hidden',
          boxShadow: shadow ? '0px 0px 8px rgba(0, 0, 0, 0.08)' : 'none'
        }}
      >
        <Box borderRadius="12px" display="flex" alignItems="center" overflow="hidden" p="12px">
          <Box
            width="103px"
            height="54px"
            borderRadius="8px"
            overflow="hidden"
            flex="1 0 auto"
            mr="8px"
            onClick={() => handleStartVideo()}
          >
            <img
              alt="img"
              src={process.env.REACT_APP_GCS_URL + download.video.thumbnailPath}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
          <Box width="100%" onClick={() => handleStartVideo()}>
            <Box>
              <Box mb="6px">
                <IonText color="secondary">
                  <h4 style={{ textAlign: 'left', fontSize: '14px', fontWeight: 700, margin: 0 }}>
                    {download.video.title}
                  </h4>
                </IonText>
              </Box>
              <IonText color="secondary">
                <h5 style={{ textAlign: 'left', fontSize: '12px', fontWeight: 400, color: '#BABAC7' }}>
                  {format(download.createdAt, 'yyyy年MM月dd日')}
                </h5>
              </IonText>
            </Box>
          </Box>
          <Box>
            {!hideMenu && (
              <StyledIonButton onClick={() => setOpen(true)}>
                <IonIcon icon={ellipsisHorizontal} style={{ color: '#BABAC7', fontSize: '18px' }} />
              </StyledIonButton>
            )}
          </Box>
        </Box>
        <IonRippleEffect />
      </div>
    </>
  )
}
