import React from 'react'
import { Box, Colors } from '../../atoms'
import { IonText, IonRouterLink } from '@ionic/react'
import { Tag } from '../../atoms'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { SP_MAX_WIDTH } from '../../../setting'
import { isPlatform } from '@ionic/core/components'

type Size = number | string
export interface CourseCardProps {
  width?: Size
  height?: Size
  title: string
  imageSrc: string
  index: string
  tagName: string
  progress?: number
  href?: string
}

const StyledLink = styled(IonRouterLink)`
  display: block;
  box-sizing: border-box;
  color: ${Colors.secondary.default};
`

export const CourseCard = (props: CourseCardProps) => {
  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })

  const {
    width = isSp ? '162px' : '267px',
    height = isSp ? '147px' : '234px',
    title,
    imageSrc,
    index,
    tagName,
    progress = 0,
    href
  } = props

  return (
    <StyledLink
      routerLink={href}
      href={href}
      routerDirection={isPlatform('capacitor') ? 'forward' : 'forward'}
      routerOptions={{ unmount: true }}
    >
      <Box
        width={width}
        height={height}
        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
        overflow="hidden"
        borderRadius="12px"
        cursor="pointer"
      >
        <Box width="100%" height="60%" position="relative" borderBottom="1px solid #EAEAEA">
          <img
            src={process.env.REACT_APP_GCS_URL + imageSrc}
            alt="video_thumbnail"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <Box
            position="absolute"
            bottom="0px"
            left="0px"
            width={progress + '%'}
            height="6px"
            backgroundColor={Colors.primary.default}
            transition="256ms"
          ></Box>
        </Box>
        <Box p={isSp ? '8px' : '16px'} backgroundColor="white">
          <Box mb={isSp ? '6px' : '12px'}>
            <Tag count={index} content={tagName} />
          </Box>
          <Box>
            <IonText>
              <h4 style={{ fontWeight: 'bold', margin: 0 }}>{title}</h4>
            </IonText>
          </Box>
        </Box>
      </Box>
    </StyledLink>
  )
}
