import { useState } from 'react'
import { axios } from '../../libs'
import { IVideoPlayHistory, IVideoFavorite } from '@open-platform/interface/src/entity'
import {
  CreateVideoFavoriteRequest,
  CreateVideoFavoriteResponse,
  DeleteVideoFavoriteRequest,
  DeleteVideoFavoriteResponse,
  ListVideoFavoritesRequest,
  ListVideoFavoritesResponse
} from '@open-platform/interface/src/request'
import useSWR, { mutate } from 'swr'
import { SWRCachePath } from '../swr-cache-path'

export const useGetVideoFavorite = (query: { videoId: string }) => {
  const { data, error } = useSWR<IVideoPlayHistory | undefined>(
    SWRCachePath.getVideoFavorite({ videoId: query.videoId }),
    async () => {
      const videoFavorites = await axios
        .get<ListVideoFavoritesResponse>('/video_favorites', { params: query })
        .then<IVideoFavorite[]>(res => res.data.videoFavorites)
      return videoFavorites[0]
    }
  )
  return {
    videoFavorite: data,
    error,
    isLoading: !data && !error
  }
}

export const useGetListVideoFavorite = (query: ListVideoFavoritesRequest['query']) => {
  const { data, error } = useSWR<IVideoPlayHistory[]>(SWRCachePath.VIDEO_FAVORITE_LIST, () =>
    axios
      .get<ListVideoFavoritesResponse>('/video_favorites', { params: query })
      .then<IVideoFavorite[]>(res => res.data.videoFavorites)
  )
  return {
    videoFavoriteList: data || [],
    error,
    isLoading: !data && !error
  }
}

export const useCreateVideoFavorite = () => {
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleCreateVideoFavorite = async (data: CreateVideoFavoriteRequest['body']) => {
    if (isLoading) return
    setIsLoading(true)
    setError(undefined)
    try {
      const videoFavorite = await axios.post<CreateVideoFavoriteResponse>('/video_favorites', data)
      setIsLoading(false)
      mutate(SWRCachePath.getVideoFavorite({ videoId: data.videoId }), videoFavorite)
      mutate(SWRCachePath.VIDEO_FAVORITE_LIST)
    } catch (err) {
      setIsLoading(false)
      setError(err)
    }
  }

  return {
    handleCreateVideoFavorite,
    error,
    isLoading
  }
}

export const useDeleteVideoFavorite = () => {
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleDeleteVideoFavorite = async (data: DeleteVideoFavoriteRequest['param'] & { videoId: string }) => {
    if (isLoading) return
    setIsLoading(true)
    setError(undefined)
    try {
      await axios.delete<DeleteVideoFavoriteResponse>(`/video_favorites/${data.videoFavoriteId}`)
      setIsLoading(false)
      mutate(SWRCachePath.getVideoFavorite({ videoId: data.videoId }), undefined)
      mutate(SWRCachePath.VIDEO_FAVORITE_LIST)
    } catch (err) {
      setIsLoading(false)
      setError(err)
    }
  }

  return {
    handleDeleteVideoFavorite,
    error,
    isLoading
  }
}
