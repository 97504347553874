import React from 'react'
import { useState } from 'react'
import { Textarea } from '../../atoms'
import { ModalConfirm } from '../ModalConfirm'

export interface ModalTextEditProps {
  defaultValue?: string
  onEdited?: (value: string | null | undefined) => void
  onCancel: () => void
  open: boolean
}

export const ModalTextEdit = ({ defaultValue, onEdited, onCancel, open }: ModalTextEditProps) => {
  const [text, setText] = useState<string | undefined | null>(defaultValue)

  return (
    <ModalConfirm
      isOpen={open}
      title="編集"
      confirmLabel="編集"
      onCancel={onCancel}
      onDismiss={onCancel}
      onConfirm={() => onEdited?.(text)}
    >
      <Textarea value={text} onIonChange={e => setText(e.detail.value)} />
    </ModalConfirm>
  )
}
