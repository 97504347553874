import { IonIcon, IonLabel, IonTabBar, IonTabButton, useIonRouter } from '@ionic/react'
import { person, home, calendar, volumeHigh, playCircle } from 'ionicons/icons'
import React from 'react'
import { isPlatform } from '@ionic/react'

export const Tabbar = () => {
  const router = useIonRouter()
  const {
    routeInfo: { pathname }
  } = router

  const isDisplay = () => {
    return !(pathname === '/login' || pathname === '/password-reset' || pathname === '/term-of-service')
  }

  return (
    <IonTabBar
      slot="bottom"
      style={{
        boxShadow: '0px -2px 4px rgba(0,0,0,0.1)',
        position: 'sticky',
        top: '100vh',
        display: isDisplay() ? 'flex' : 'none'
      }}
      mode="ios"
      //   color="primary"
    >
      <IonTabButton tab="/" href="/" selected={pathname === '/'}>
        <IonIcon icon={home} style={{ fontSize: '20px' }} />
        <IonLabel>ホーム</IonLabel>
      </IonTabButton>

      {isPlatform('capacitor') && (
        <IonTabButton tab="downloads" href="/downloads" selected={pathname === '/downloads'}>
          <IonIcon icon={playCircle} style={{ fontSize: '20px' }} />
          <IonLabel>動画</IonLabel>
        </IonTabButton>
      )}
      <IonTabButton tab="schedule" href="/schedule" selected={pathname === '/schedule'}>
        <IonIcon icon={calendar} style={{ fontSize: '20px' }} />
        <IonLabel>カレンダー</IonLabel>
      </IonTabButton>

      <IonTabButton tab="notification" href="/notification" selected={pathname === '/notification'}>
        <IonIcon icon={volumeHigh} style={{ fontSize: '20px' }} />
        <IonLabel>お知らせ</IonLabel>
      </IonTabButton>
      <IonTabButton tab="mypage" href="/mypage" selected={pathname === '/mypage'}>
        <IonIcon icon={person} style={{ fontSize: '20px' }} />
        <IonLabel>マイページ</IonLabel>
      </IonTabButton>
    </IonTabBar>
  )
}
