import React from 'react'
import { Layout } from '../../Layout'
import { PageProfileEdit } from '../../organisms'
import { Box, Colors } from '../../atoms'
import { RouteComponentProps } from 'react-router'
import { useMediaQuery } from 'react-responsive'
import { SP_MAX_WIDTH } from '../../../setting'

export interface MyPageProps extends RouteComponentProps {
  name?: string
}

export const MyPageEdit = (_: MyPageProps) => {
  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })
  return (
    <Layout>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        backgroundColor={isSp ? 'white' : Colors.bg.default}
      >
        <PageProfileEdit />
      </Box>
    </Layout>
  )
}
