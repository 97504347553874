import React from 'react'

import { IonIcon } from '@ionic/react'
import { starOutline, star } from 'ionicons/icons'

import { IVideo } from '@open-platform/interface/src/entity'
import { useCreateVideoFavorite, useDeleteVideoFavorite, useGetVideoFavorite } from '../../../modules/videoFavorite'
import { Box, Colors } from '../../atoms'
import { useCallback } from 'react'

export interface FavoriteButtonProps {
  video: IVideo
}

export const FavoriteButton = ({ video }: FavoriteButtonProps) => {
  const { handleCreateVideoFavorite } = useCreateVideoFavorite()
  const { handleDeleteVideoFavorite } = useDeleteVideoFavorite()
  const { videoFavorite } = useGetVideoFavorite({ videoId: video.id })

  const handleClickFavorite = useCallback(() => {
    handleCreateVideoFavorite({ videoId: video.id })
  }, [handleCreateVideoFavorite, video.id])

  const handleToggleFavorite = useCallback(() => {
    videoFavorite
      ? handleDeleteVideoFavorite({ videoFavoriteId: videoFavorite.id, videoId: video.id })
      : handleClickFavorite()
  }, [handleClickFavorite, handleDeleteVideoFavorite, video.id, videoFavorite])
  return (
    <Box
      width="40px"
      height="40px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
      backgroundColor="rgba(0, 0, 0, 0.4)"
      pointerEvents="auto"
      onClick={() => handleToggleFavorite()}
    >
      {videoFavorite ? (
        <IonIcon icon={star} style={{ fontSize: '20px', color: Colors.primary.default }} />
      ) : (
        <IonIcon icon={starOutline} style={{ fontSize: '20px' }} />
      )}
    </Box>
  )
}
