import React from 'react'
import { Profile } from '../Profile'

import { IonLoading } from '@ionic/react'
import { useGetMe } from '../../../modules/me'

export const PageMyPage = () => {
  const { user } = useGetMe()
  return user ? <Profile user={user} canEdit={true} /> : <IonLoading isOpen={true} />
}
