import React from 'react'
import { JSX } from '@ionic/core/components'
import { IonInput } from '@ionic/react'
import styled from 'styled-components'
import { Colors } from '..'

const StyledInput = styled(IonInput)`
  font-family: 'SF Pro Display', 'Hiragino Kaku Gothic ProN', 'メイリオ', sans-serif;
  border: 1px solid ${Colors.secondary[200]};
  border-radius: 2px;
  font-size: 12px;
  font-weight: bold;
  color: ${Colors.secondary.default};
  height: 36px;
  line-height: normal;
  padding-right: 8px !important;
  padding-left: 8px !important;
  border-radius: 12px;
  overflow: hidden;
`

export const Input = (
  props: JSX.IonInput &
    Pick<
      import('react').HTMLAttributes<HTMLIonInputElement>,
      | 'hidden'
      | 'dir'
      | 'slot'
      | 'title'
      | 'color'
      | 'translate'
      | 'prefix'
      | 'children'
      | 'className'
      | 'defaultChecked'
      | 'defaultValue'
      | 'suppressContentEditableWarning'
      | 'suppressHydrationWarning'
      | 'accessKey'
      | 'contentEditable'
      | 'contextMenu'
      | 'draggable'
      | 'id'
      | 'lang'
      | 'placeholder'
      | 'spellCheck'
      | 'tabIndex'
      | 'radioGroup'
      | 'role'
      | 'about'
      | 'datatype'
      | 'inlist'
      | 'property'
      | 'resource'
      | 'typeof'
      | 'vocab'
      | 'autoCapitalize'
      | 'autoCorrect'
      | 'autoSave'
      | 'itemProp'
      | 'itemScope'
      | 'itemType'
      | 'itemID'
      | 'itemRef'
      | 'results'
      | 'security'
      | 'unselectable'
      | 'inputMode'
      | 'is'
      | 'aria-activedescendant'
      | 'aria-atomic'
      | 'aria-autocomplete'
      | 'aria-busy'
      | 'aria-checked'
      | 'aria-colcount'
      | 'aria-colindex'
      | 'aria-colspan'
      | 'aria-controls'
      | 'aria-current'
      | 'aria-describedby'
      | 'aria-details'
      | 'aria-disabled'
      | 'aria-dropeffect'
      | 'aria-errormessage'
      | 'aria-expanded'
      | 'aria-flowto'
      | 'aria-grabbed'
      | 'aria-haspopup'
      | 'aria-hidden'
      | 'aria-invalid'
      | 'aria-keyshortcuts'
      | 'aria-label'
      | 'aria-labelledby'
      | 'aria-level'
      | 'aria-live'
      | 'aria-modal'
      | 'aria-multiline'
      | 'aria-multiselectable'
      | 'aria-orientation'
      | 'aria-owns'
      | 'aria-placeholder'
      | 'aria-posinset'
      | 'aria-pressed'
      | 'aria-readonly'
      | 'aria-relevant'
      | 'aria-required'
      | 'aria-roledescription'
      | 'aria-rowcount'
      | 'aria-rowindex'
      | 'aria-rowspan'
      | 'aria-selected'
      | 'aria-setsize'
      | 'aria-sort'
      | 'aria-valuemax'
      | 'aria-valuemin'
      | 'aria-valuenow'
      | 'aria-valuetext'
      | 'dangerouslySetInnerHTML'
      | 'onCopy'
      | 'onCopyCapture'
      | 'onCut'
      | 'onCutCapture'
      | 'onPaste'
      | 'onPasteCapture'
      | 'onCompositionEnd'
      | 'onCompositionEndCapture'
      | 'onCompositionStart'
      | 'onCompositionStartCapture'
      | 'onCompositionUpdate'
      | 'onCompositionUpdateCapture'
      | 'onFocus'
      | 'onFocusCapture'
      | 'onBlur'
      | 'onBlurCapture'
      | 'onChange'
      | 'onChangeCapture'
      | 'onBeforeInput'
      | 'onBeforeInputCapture'
      | 'onInput'
      | 'onInputCapture'
      | 'onReset'
      | 'onResetCapture'
      | 'onSubmit'
      | 'onSubmitCapture'
      | 'onInvalid'
      | 'onInvalidCapture'
      | 'onLoad'
      | 'onLoadCapture'
      | 'onError'
      | 'onErrorCapture'
      | 'onKeyDown'
      | 'onKeyDownCapture'
      | 'onKeyPress'
      | 'onKeyPressCapture'
      | 'onKeyUp'
      | 'onKeyUpCapture'
      | 'onAbort'
      | 'onAbortCapture'
      | 'onCanPlay'
      | 'onCanPlayCapture'
      | 'onCanPlayThrough'
      | 'onCanPlayThroughCapture'
      | 'onDurationChange'
      | 'onDurationChangeCapture'
      | 'onEmptied'
      | 'onEmptiedCapture'
      | 'onEncrypted'
      | 'onEncryptedCapture'
      | 'onEnded'
      | 'onEndedCapture'
      | 'onLoadedData'
      | 'onLoadedDataCapture'
      | 'onLoadedMetadata'
      | 'onLoadedMetadataCapture'
      | 'onLoadStart'
      | 'onLoadStartCapture'
      | 'onPause'
      | 'onPauseCapture'
      | 'onPlay'
      | 'onPlayCapture'
      | 'onPlaying'
      | 'onPlayingCapture'
      | 'onProgress'
      | 'onProgressCapture'
      | 'onRateChange'
      | 'onRateChangeCapture'
      | 'onSeeked'
      | 'onSeekedCapture'
      | 'onSeeking'
      | 'onSeekingCapture'
      | 'onStalled'
      | 'onStalledCapture'
      | 'onSuspend'
      | 'onSuspendCapture'
      | 'onTimeUpdate'
      | 'onTimeUpdateCapture'
      | 'onVolumeChange'
      | 'onVolumeChangeCapture'
      | 'onWaiting'
      | 'onWaitingCapture'
      | 'onAuxClick'
      | 'onAuxClickCapture'
      | 'onClick'
      | 'onClickCapture'
      | 'onContextMenu'
      | 'onContextMenuCapture'
      | 'onDoubleClick'
      | 'onDoubleClickCapture'
      | 'onDrag'
      | 'onDragCapture'
      | 'onDragEnd'
      | 'onDragEndCapture'
      | 'onDragEnter'
      | 'onDragEnterCapture'
      | 'onDragExit'
      | 'onDragExitCapture'
      | 'onDragLeave'
      | 'onDragLeaveCapture'
      | 'onDragOver'
      | 'onDragOverCapture'
      | 'onDragStart'
      | 'onDragStartCapture'
      | 'onDrop'
      | 'onDropCapture'
      | 'onMouseDown'
      | 'onMouseDownCapture'
      | 'onMouseEnter'
      | 'onMouseLeave'
      | 'onMouseMove'
      | 'onMouseMoveCapture'
      | 'onMouseOut'
      | 'onMouseOutCapture'
      | 'onMouseOver'
      | 'onMouseOverCapture'
      | 'onMouseUp'
      | 'onMouseUpCapture'
      | 'onSelect'
      | 'onSelectCapture'
      | 'onTouchCancel'
      | 'onTouchCancelCapture'
      | 'onTouchEnd'
      | 'onTouchEndCapture'
      | 'onTouchMove'
      | 'onTouchMoveCapture'
      | 'onTouchStart'
      | 'onTouchStartCapture'
      | 'onPointerDown'
      | 'onPointerDownCapture'
      | 'onPointerMove'
      | 'onPointerMoveCapture'
      | 'onPointerUp'
      | 'onPointerUpCapture'
      | 'onPointerCancel'
      | 'onPointerCancelCapture'
      | 'onPointerEnter'
      | 'onPointerEnterCapture'
      | 'onPointerLeave'
      | 'onPointerLeaveCapture'
      | 'onPointerOver'
      | 'onPointerOverCapture'
      | 'onPointerOut'
      | 'onPointerOutCapture'
      | 'onGotPointerCapture'
      | 'onGotPointerCaptureCapture'
      | 'onLostPointerCapture'
      | 'onLostPointerCaptureCapture'
      | 'onScroll'
      | 'onScrollCapture'
      | 'onWheel'
      | 'onWheelCapture'
      | 'onAnimationStart'
      | 'onAnimationStartCapture'
      | 'onAnimationEnd'
      | 'onAnimationEndCapture'
      | 'onAnimationIteration'
      | 'onAnimationIterationCapture'
      | 'onTransitionEnd'
      | 'onTransitionEndCapture'
    > &
    import('react').RefAttributes<HTMLIonInputElement>
) => {
  return <StyledInput {...props} mode="md" />
}
