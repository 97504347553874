import { IVideoComment, IUser, IVideo, ITerm } from '@open-platform/interface/src/entity'
import { image, name, random, internet, date } from 'faker'

//- video, term は共通の値
const dummyTerm = {
  id: random.uuid(),
  period: '202009',
  textbookPath: image.business(),
  publishAt: date.past().getTime(),
  createdAt: date.past().getTime(),
  updatedAt: date.past().getTime()
}

const dummyVideo = {
  id: random.uuid(),
  path: image.business(),
  thumbnailPath: image.business(),
  title: random.words(10),
  description: random.words(50),
  sortIndex: random.number({ min: 0, max: 10 }),
  createdAt: date.past().getTime(),
  updatedAt: date.past().getTime()
}

//- factory
const userFactory = (): IUser => {
  return {
    id: random.uuid(),
    displayName: name.firstName(),
    iconImagePath: image.avatar(),
    profile: random.words(30),
    email: internet.email(),
    role: 'CUSTOMER',
    availablePeriod: '202009',
    status: 'ACTIVE',
    createdAt: date.past().getTime(),
    updatedAt: date.past().getTime()
  }
}

const videoFactory = (): IVideo => {
  return {
    id: dummyVideo.id,
    path: dummyVideo.path,
    downloadPath: dummyVideo.path,
    thumbnailPath: dummyVideo.thumbnailPath,
    title: dummyVideo.title,
    description: dummyVideo.description,
    sortIndex: dummyVideo.sortIndex,
    term: termFactory(),
    status: 'ACTIVE',
    createdAt: dummyVideo.createdAt,
    updatedAt: dummyTerm.updatedAt,
    audioPath: 'hoge'
  }
}

const termFactory = (): ITerm => {
  return {
    id: dummyTerm.id,
    period: dummyTerm.period,
    textbookPath: dummyTerm.textbookPath,
    textbookOrientation: 'PORTRAIT',
    publishAt: dummyTerm.publishAt,
    status: 'ACTIVE',
    createdAt: dummyTerm.createdAt,
    updatedAt: dummyTerm.updatedAt
  }
}

export const commentFactory = (): IVideoComment => {
  return {
    id: random.uuid(),
    text: random.words(30),
    user: userFactory(),
    video: videoFactory(),
    createdAt: date.past().getTime(),
    updatedAt: date.past().getTime()
  }
}

export const commentList = [...Array(10)].map(() => commentFactory())
