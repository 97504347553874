import React, { useEffect } from 'react'
import { Box, Colors } from '../../atoms'
import { PdfViewer, MainContentCard } from '../../molecules'
import { Playlist } from '../'
import { IVideo, IVideoComment } from '@open-platform/interface/src/entity'
import { IonIcon, IonText } from '@ionic/react'
import { close, eyeOutline } from 'ionicons/icons'
import { useState } from 'react'
import { useGetVideoList } from '../../../modules/video'
import { useCreateVideoPlayHistory } from '../../../modules/videoPlayHistory'
import { SP_MAX_WIDTH } from '../../../setting'
import { useMediaQuery } from 'react-responsive'
import { useMemo } from 'react'

type Size = number | string
export interface CourseProps {
  width?: Size
  height?: Size
  maxWidth?: Size
  video: IVideo
  commentList?: IVideoComment[]
  pdfUrl?: string
}

export const Course = ({ width = '100%', height, video, commentList, pdfUrl }: CourseProps) => {
  const [isFullScreen, setIsFullScreen] = useState(false)
  const { videos } = useGetVideoList({ termId: video.term.id })
  const { handleCreateVideoHistory } = useCreateVideoPlayHistory()
  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })
  // video id が変わるたびに視聴履歴を更新する
  useEffect(() => {
    handleCreateVideoHistory({ videoId: video.id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video.id])

  // 右側の横幅は PDF の向きに応じて変える
  const rightSideWidth = useMemo(() => {
    return video.term.textbookOrientation === 'LANDSCAPE' ? '46' : '33'
  }, [video.term.textbookOrientation])

  const toggleRightSection = () => {
    setIsFullScreen(!isFullScreen)
  }

  return (
    <Box maxWidth="1200px" width="100vw" mx="auto" px={isSp ? '0px' : '32px'}>
      {!isSp && (
        <Box width="100%" display="flex" justifyContent="flex-end" transition="256ms">
          {!isFullScreen ? (
            <Box p="16px" cursor="pointer" onClick={toggleRightSection}>
              <IonIcon icon={close} style={{ color: '#000000' }} />
            </Box>
          ) : (
            <Box p="16px" cursor="pointer" onClick={toggleRightSection} display="flex" alignItems="center">
              <Box mr="8px">
                <IonText>
                  <h4 style={{ color: Colors.secondary.default, margin: 0 }}>資料を見る</h4>
                </IonText>
              </Box>
              <Box display="flex" alignItems="center">
                <IonIcon icon={eyeOutline} style={{ color: Colors.secondary.default, fontSize: '20px' }} />
              </Box>
            </Box>
          )}
        </Box>
      )}

      <Box width={width} height={height} display="flex">
        <Box width="100%">
          {video && <MainContentCard videoHeight="60vh" video={video} videoCommentList={commentList} />}
        </Box>
        {!isSp && (
          <Box
            display="flex"
            flexDirection="column"
            width={!isFullScreen ? `${rightSideWidth}vw` : '0%'}
            minWidth={!isFullScreen ? `${rightSideWidth}%` : '0%'}
            maxWidth={!isFullScreen ? `${rightSideWidth}%` : '0%'}
            flex="0 1 auto"
            ml={!isFullScreen ? '32px' : '0px'}
            transition="256ms"
            opacity={!isFullScreen ? '100' : '0'}
          >
            {pdfUrl && (
              <Box mb="16px">
                <PdfViewer width="100%" url={pdfUrl} video={video} />
              </Box>
            )}
            <Box>
              <Playlist courseList={videos} currentId={video.id} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
