import { axios } from '../../libs'
import useSWR from 'swr'
import {
  GetAnnouncementRequest,
  GetAnnouncementResponse,
  ListAnnouncementsRequest,
  ListAnnouncementsResponse
} from '@open-platform/interface/src/request'
import { SWRCachePath } from '../swr-cache-path'
import { IAnnouncement } from '@open-platform/interface/src/entity'

export const useGetListAnnouncement = ({ page = 1, perPage = 12 }: ListAnnouncementsRequest['query']) => {
  const { data, error } = useSWR<ListAnnouncementsResponse>(SWRCachePath.getListAnnouncement({ page, perPage }), () => {
    return axios.get<ListAnnouncementsResponse>(`/announcements?page=${page}&perPage=${perPage}`).then(res => res.data)
  })
  return {
    listAnnouncement: data?.announcements,
    pagination: data?.pagination,
    error,
    isLoading: !data && !error
  }
}

export const useGetAnnouncement = ({ announcementId }: GetAnnouncementRequest['param']) => {
  const { data, error } = useSWR<IAnnouncement>(SWRCachePath.getAnnouncement({ announcementId }), () => {
    return axios
      .get<GetAnnouncementResponse>(`/announcements/${announcementId}`)
      .then<IAnnouncement>(res => res.data.announcement)
  })
  return {
    announcement: data,
    error,
    isLoading: !data && !error
  }
}
