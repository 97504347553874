import axios from 'axios'
import { auth } from '@open-platform/firebase-client'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.interceptors.request.use(
  async requestConfig => {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken()
      requestConfig.headers['Authorization'] = `Bearer ${token}`
    }
    return requestConfig
  },
  error => {
    return Promise.reject(error)
  }
)

export { axios }
