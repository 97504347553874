import React, { ReactElement, useState } from 'react'
import { auth } from '@open-platform/firebase-client'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Route, RouteProps, Redirect } from 'react-router'
import { IonRouterOutlet } from '@ionic/react'

export interface AuthProps {
  children: ReactElement<RouteProps> | ReactElement<RouteProps>[]
}

const isArray = (arg: ReactElement<RouteProps> | ReactElement<RouteProps>[]): arg is ReactElement<RouteProps>[] => {
  return Array.isArray(arg)
}

export const Auth: React.FC<AuthProps> = ({ children }) => {
  const [user, loading] = useAuthState(auth)
  const [childComponents] = useState(isArray(children) ? children : [children])

  return (
    <IonRouterOutlet id="main-content">
      {childComponents.map((item, index) => {
        const Component = item.props.component
        return (
          <Route
            key={index}
            path={item.props.path}
            exact={item.props.exact}
            render={props =>
              user ? <Component {...props} /> : loading ? null : <Redirect to={{ pathname: '/login' }} />
            }
          />
        )
      })}
    </IonRouterOutlet>
  )
}
