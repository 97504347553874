import { isPlatform } from '@ionic/core/components'
import { PushNotifications, ActionPerformed, Token } from '@capacitor/push-notifications'
import { useCallback, useEffect } from 'react'
import { axios } from '../../libs'
import { useGetMe } from '../me'
import { CreateDeviceTokenRequest, CreateDeviceTokenResponse } from '@open-platform/interface/src/request'
import { INotificationType } from '@open-platform/interface/src/entity'

export const useNotification = () => {
  const { user } = useGetMe()

  useEffect(() => {
    //   capacitor で register
    if (!isPlatform('capacitor')) return
    PushNotifications.addListener('registration', (token: Token) => {
      axios
        .post<CreateDeviceTokenRequest['body']>('/device_tokens', {
          token: token.value,
          deviceType: isPlatform('ios') ? 'ios' : 'android'
        })
        .then<CreateDeviceTokenResponse>()
    })

    PushNotifications.addListener('pushNotificationActionPerformed', (res: ActionPerformed) => {
      const notificationType = res.notification.data?.['notificationType'] as INotificationType
      const targetResourceId = res.notification.data?.['targetResourceId'] as string | undefined
      if (notificationType === 'announcement') {
        console.log('====================================================================')
        console.log({ targetResourceId })
        console.log('====================================================================')
        window.location.href = `/announcements/${targetResourceId}`
      } else {
        window.location.href = '/notification'
      }
    })

    return () => {
      PushNotifications.removeAllListeners()
    }
  }, [user])
}

export const useNotificaitonRegister = () => {
  const handleNotificationRegister = useCallback(() => {
    if (isPlatform('capacitor')) {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          console.log({ result })
          PushNotifications.register()
        } else {
          // Show some error
        }
      })
    }
  }, [])
  return { handleNotificationRegister }
}
