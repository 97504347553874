import React from 'react'
import { Layout } from '../../Layout'
import { PageHome } from '../../organisms/PageHome'
import { Box } from '../../atoms'
import { useGetLatestVideo } from '../../../modules/video'

export interface HomeProps {
  name?: string
}

export const Home = (_: HomeProps) => {
  const { video } = useGetLatestVideo()
  return (
    <Layout>
      <Box display="flex" justifyContent="center">
        {video && <PageHome video={video} />}
      </Box>
    </Layout>
  )
}
