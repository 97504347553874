import { App, AppState } from '@capacitor/app'
import { PushNotifications } from '@capacitor/push-notifications'
import { useEffect } from 'react'
import { useGetMe } from '../me'
import { useNotificaitonRegister } from '../notification'
import { FirebaseRemoteConfig } from '@capacitor-firebase/remote-config'
import { isPlatform } from '@ionic/react'
import { compareVersions } from 'compare-versions'
import { Dialog } from '@capacitor/dialog'

// 強制アップデートをかけたい
const checkUpdate = async () => {
  if (!isPlatform('capacitor')) return
  // activate
  await FirebaseRemoteConfig.fetchAndActivate()
  await FirebaseRemoteConfig.fetchConfig({
    minimumFetchIntervalInSeconds: 10
  })
  // ios の場合
  if (isPlatform('ios')) {
    const { value: iosStoreUrl } = await FirebaseRemoteConfig.getString({
      key: 'ios_store_url'
    })
    const { value: iosStoreVersion } = await FirebaseRemoteConfig.getString({
      key: 'ios_store_version'
    })
    const info = await App.getInfo()
    const currentVersion = info.version

    const isNeedUpdate = compareVersions(currentVersion, iosStoreVersion) === -1

    console.log({ currentVersion, iosStoreVersion, iosStoreUrl, isNeedUpdate })

    if (isNeedUpdate) {
      await Dialog.alert({
        title: 'お知らせ',
        message: '新しいアプリのアップデートがあります。更新してください'
      })
      window.open(iosStoreUrl, '_blank')
    }
  }
  // android の場合
  if (isPlatform('android')) {
    const { value: androidStoreUrl } = await FirebaseRemoteConfig.getString({
      key: 'android_store_url'
    })
    const { value: androidStoreVersion } = await FirebaseRemoteConfig.getString({
      key: 'android_store_version'
    })
    const info = await App.getInfo()
    const currentVersion = info.version

    const isNeedUpdate = compareVersions(currentVersion, androidStoreVersion) === -1

    console.log({ currentVersion, androidStoreVersion, androidStoreUrl, isNeedUpdate })

    if (isNeedUpdate) {
      await Dialog.alert({
        title: 'お知らせ',
        message: '新しいアプリのアップデートがあります。更新してください'
      })
      window.open(androidStoreUrl, '_blank')
    }
  }
}

export const useRegisterApplicationListner = () => {
  const { handleNotificationRegister } = useNotificaitonRegister()
  const { user } = useGetMe()
  useEffect(() => {
    App.addListener('appStateChange', (state: AppState) => {
      // state.isActive contains the active state
      console.log('App state changed. Is active?', state.isActive)
      if (user?.id && state.isActive) {
        handleNotificationRegister()
        PushNotifications.removeAllDeliveredNotifications()
      }

      if (state.isActive) {
        checkUpdate()
      }
    })
    App.addListener('appUrlOpen', (data: any) => {
      console.log('App opened with URL: ' + data.url)
    })
    App.addListener('appRestoredResult', (data: any) => {
      console.log('Restored state:', data)
    })

    return () => {
      App.removeAllListeners()
    }
  }, [handleNotificationRegister, user])

  useEffect(() => {
    checkUpdate()
  }, [])
}
