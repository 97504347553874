import React, { useMemo, useState } from 'react'
import { Box } from '../'
import { IonPopover, IonText } from '@ionic/react'
import { Colors } from '../Color/Colors'
import styled from 'styled-components'
import { SP_MAX_WIDTH } from '../../../setting'
import { useMediaQuery } from 'react-responsive'

type Size = number | string
export interface SelectBoxProps {
  width?: Size
  options?: SelectBoxOption[]
  value?: string
  placeholder?: string
  onSelected: (value: string) => void
}

export interface SelectBoxOption {
  label: string
  value: string
}

const SELECTED_COLOR = '#DE4346'
const DEFAULT_COLOR = '#DEDEDE'

export const SelectBox = (props: SelectBoxProps) => {
  const [showPopover, setShowPopover] = useState<{ open: boolean; event: Event | undefined }>({
    open: false,
    event: undefined
  })

  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })

  const { width = isSp ? '130px' : '240px', options = [], value, onSelected, placeholder } = props

  const selectedItem = useMemo(() => {
    return options.find(item => item.value === value)
  }, [options, value])

  const StyledPopover = styled(IonPopover)`
    --minWidth: ${width};
    --width: ${isSp ? '60%' : width};
    --maxWidth: ${width}
    --box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    .popover-content.sc-ion-popover-md {
      height: 300px;
      border-radius: 12px;
      z-index: 999;
    }
    .popover-viewport.sc-ion-popover-md {
      padding: 0;
      width: 200px !important;
      height: 300px !important;
      overflow: auto;
      z-index: 999;
    }
    .popover-content.sc-ion-popover-md {
      width: 200px !important;
      z-index: 999;
    }

  `

  const handleClickItem = (value: string) => {
    onSelected?.(value)
    setShowPopover({ open: false, event: undefined })
  }

  return (
    <>
      <StyledPopover
        mode="md"
        isOpen={showPopover.open}
        cssClass="my-custom-class"
        onDidDismiss={() => setShowPopover({ open: false, event: undefined })}
        event={showPopover.event}
        showBackdrop={false}
      >
        {options.map((item, idx) => (
          <Box
            key={idx}
            py="20px"
            px="16px"
            display="flex"
            alignItems="center"
            cursor="pointer"
            onClick={() => handleClickItem(item.value)}
            backgroundColor="white"
          >
            <Box
              width="16px"
              height="16px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
              backgroundColor={value === item.value ? SELECTED_COLOR : DEFAULT_COLOR}
              mr="10px"
            >
              <Box
                width="14px"
                height="14px"
                backgroundColor={value === item.value ? SELECTED_COLOR : DEFAULT_COLOR}
                border="1px solid white"
                borderRadius="50%"
              />
            </Box>
            <Box display="flex" alignItems="center">
              <IonText>
                <h4
                  style={{
                    color: value === item.value ? Colors.primary.default : Colors.secondary[300],
                    margin: '0px'
                  }}
                >
                  {item.label}
                </h4>
              </IonText>
            </Box>
          </Box>
        ))}
      </StyledPopover>
      <Box
        onClick={e => {
          setShowPopover({ open: true, event: e.nativeEvent })
        }}
        border={`1px solid ${Colors.secondary[200]}`}
        borderRadius="12px"
        color={Colors.secondary[200]}
        minWidth={width}
        width="100%"
        py={isSp ? '6px' : '8px'}
        px="15px"
        cursor="pointer"
      >
        <IonText>
          <h5
            style={{
              color: selectedItem ? Colors.primary.default : Colors.secondary[300],
              margin: 0,
              fontWeight: selectedItem ? 'bold' : 'normal'
            }}
          >
            {selectedItem ? selectedItem.label : placeholder || '選択されていません'}
          </h5>
        </IonText>
      </Box>
    </>
  )
}
