import React from 'react'

import { Box } from '../../atoms'
import { IonText } from '@ionic/react'

export const Offline = () => {
  return (
    <Box py="40px">
      <IonText color="secondary">
        <h3 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 700, margin: 0, lineHeight: 1.5 }}>
          現在オフライン接続です
        </h3>
      </IonText>
    </Box>
  )
}
