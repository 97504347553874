import React from 'react'

type Size = number | string
export interface BoxProps {
  width?: Size
  maxWidth?: Size
  minWidth?: Size
  height?: Size
  maxHeight?: Size
  minHeight?: Size
  position?:
    | 'inherit'
    | '-moz-initial'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'static'
    | 'absolute'
    | '-webkit-sticky'
    | 'fixed'
    | 'relative'
    | 'sticky'
  display?: 'flex' | 'block' | 'inline' | 'inline-block' | 'none'
  justifyContent?: 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'center'
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline'
  flex?: string
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  mt?: Size
  mr?: Size
  mb?: Size
  ml?: Size
  m?: Size
  mx?: Size
  my?: Size
  pt?: Size
  pr?: Size
  pb?: Size
  pl?: Size
  p?: Size
  px?: Size
  py?: Size
  top?: Size
  right?: Size
  bottom?: Size
  left?: Size
  backgroundColor?: string
  border?: string
  borderRadius?: Size
  color?: string
  overflow?: 'hidden' | 'visible' | 'scroll' | 'auto' | 'inherit' | '-moz-initial' | 'initial' | 'revert' | 'unset'
  overflowX?: 'hidden' | 'visible' | 'scroll' | 'auto' | 'inherit' | '-moz-initial' | 'initial' | 'revert' | 'unset'
  overflowY?: 'hidden' | 'visible' | 'scroll' | 'auto' | 'inherit' | '-moz-initial' | 'initial' | 'revert' | 'unset'
  className?: string
  cursor?:
    | 'auto'
    | 'default'
    | 'none'
    | 'context-menu'
    | 'help'
    | 'pointer'
    | 'progress'
    | 'wait'
    | 'cell'
    | 'crosshair'
    | 'text'
    | 'vertical-text'
    | 'alias'
    | 'copy'
    | 'move'
    | 'no-drop'
    | 'not-allowed'
    | 'e-resize'
    | 'n-resize'
    | 'ne-resize'
    | 'nw-resize'
    | 's-resize'
    | 'se-resize'
    | 'sw-resize'
    | 'w-resize'
    | 'ew-resize'
    | 'ns-resize'
    | 'nesw-resize'
    | 'nwse-resize'
    | 'col-resize'
    | 'row-resize'
    | 'all-scroll'
    | 'zoom-in'
    | 'zoom-out'
    | 'grab'
    | 'grabbing'
  boxSizing?: 'content-box' | 'border-box' | 'inherit'
  boxShadow?: string
  transition?: string
  borderTop?: string
  borderRight?: string
  borderBottom?: string
  borderLeft?: string
  opacity?: string
  pointerEvents?: 'none' | 'auto'
  zIndex?: '-moz-initial' | 'inherit' | 'initial' | 'revert' | 'unset' | 'auto' | (number & {}) | undefined
  visibility?: '-moz-initial' | 'inherit' | 'initial' | 'revert' | 'unset' | 'hidden' | 'visible' | 'collapse'
  flexWrap?: '-moz-initial' | 'inherit' | 'initial' | 'revert' | 'unset' | 'nowrap' | 'wrap' | 'wrap-reverse'
  textAlign?: 'center' | 'right' | 'left'
  onClick?: (e: React.MouseEvent) => void
  onMouseOver?: (e: React.MouseEvent) => void
  onMouseOut?: (e: React.MouseEvent) => void
  onMouseLeave?: (e: React.MouseEvent) => void
}

const getPadding = ({
  p = '0px',
  px,
  py,
  pt,
  pr,
  pb,
  pl
}: {
  p?: Size
  px?: Size
  py?: Size
  pt?: Size
  pr?: Size
  pb?: Size
  pl?: Size
}) => {
  const paddingTop = pt || py || p
  const paddingRight = pr || px || p
  const paddingBottom = pb || py || p
  const paddingLeft = pl || px || p
  if (!paddingTop && !paddingRight && !paddingBottom && !paddingLeft) return undefined
  return `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`
}

const getMargin = ({
  m = 0,
  mx,
  my,
  mt,
  mr,
  mb,
  ml
}: {
  m?: Size
  mx?: Size
  my?: Size
  mt?: Size
  mr?: Size
  mb?: Size
  ml?: Size
}) => {
  const marginTop = mt || my || m
  const marginRight = mr || mx || m
  const marginBottom = mb || my || m
  const marginLeft = ml || mx || m
  if (!marginTop && !marginRight && !marginBottom && !marginLeft) return undefined
  return `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`
}

export const Box: React.FC<BoxProps> = ({
  width,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  display,
  justifyContent,
  alignItems,
  flex,
  flexDirection,
  backgroundColor,
  border,
  borderRadius,
  color,
  overflow,
  overflowX,
  overflowY,
  boxSizing,
  m = 0,
  mt,
  mr,
  mb,
  ml,
  mx,
  my,
  p = 0,
  pt,
  pr,
  pb,
  pl,
  px,
  py,
  top,
  right,
  bottom,
  left,
  cursor,
  boxShadow,
  position,
  transition,
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
  opacity,
  pointerEvents,
  zIndex,
  visibility,
  flexWrap,
  textAlign,
  onClick,
  onMouseOver,
  onMouseOut,
  onMouseLeave,
  children,
  className
}) => {
  return (
    <div
      className={className}
      style={{
        width,
        maxWidth,
        minWidth,
        height,
        maxHeight,
        minHeight,
        position,
        display,
        justifyContent,
        alignItems,
        flex,
        flexDirection,
        margin: getMargin({ m, mt, mr, mb, ml, mx, my }),
        padding: getPadding({ p, pt, pr, pb, pl, px, py }),
        backgroundColor,
        borderRadius,
        color,
        border,
        borderTop: borderTop || border,
        borderRight: borderRight || border,
        borderBottom: borderBottom || border,
        borderLeft: borderLeft || border,
        overflow,
        overflowX: overflowX || overflow,
        overflowY: overflowY || overflow,
        boxSizing,
        cursor,
        top,
        right,
        left,
        bottom,
        boxShadow,
        opacity,
        transition,
        pointerEvents,
        zIndex,
        visibility,
        flexWrap,
        textAlign
      }}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  )
}
