import React, { useEffect, useState } from 'react'
import { IonIcon, IonText } from '@ionic/react'
import styled from 'styled-components'
import { Box, Colors } from '../../atoms'
import { Markdown } from '../../atoms/MarkdownParser'
import { chevronDown } from 'ionicons/icons'
import { IAnnouncement } from '@open-platform/interface/src/entity'
import { format } from 'date-fns'
import { useMediaQuery } from 'react-responsive'
import { SP_MAX_WIDTH } from '../../../setting'

export interface NotificationItemProps {
  announcement: IAnnouncement
  defaultOpen?: boolean
}

const StyledMarkdown = styled.div`
  > * {
    color: ${Colors.secondary.default};
  }
  > h1 {
    font-size: 40px;
  }
  > h2 {
    font-size: 26px;
  }
  > h3 {
    font-size: 20px;
  }

  > p {
    /* display: flex; */
    font-size: 16px;
    white-space: pre-wrap;
    word-break: break-all;
    margin: 12px 0px;
    line-height: 1.2;
  }
  > p img {
    max-width: 200px;
    object-fit: cover;
    cursor: pointer;
  }
  > p a {
    color: ${Colors.primary.default};
  }
  > blockquote {
    color: ${Colors.secondary[300]};
    padding: 0px;
    padding-left: 12px;
    margin: 0px;
    border-left: 3px solid ${Colors.secondary[300]};
    word-break: break-all;
    white-space: pre-wrap;
  }

  > p code {
    display: inline-block;
    align-items: center;
    padding: 1px 8px;
    margin: 0px 2px;
    background-color: black;
    font-size: 12px;
    color: white;
  }

  ul {
    margin-left: -20px;
    margin-bottom: 8px;
  }
`

const kindMap = {
  seminar: 'セミナー',
  event: 'イベント',
  broadcast: '映像配信'
}
export const NotificationItem = ({ announcement, defaultOpen = false }: NotificationItemProps) => {
  const [open, setIsOpen] = useState(false)
  const handleToggleAccordion = () => {
    setIsOpen(!open)
  }
  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })

  useEffect(() => {
    if (defaultOpen) {
      setIsOpen(true)
    }
  }, [defaultOpen])

  return (
    <Box
      backgroundColor="white"
      borderRadius="12px"
      overflow="hidden"
      p={isSp ? '16px' : '24px'}
      position="relative"
      boxShadow={isSp ? '0px 0px 8px 0px rgba(0,0,0,0.08)' : 'none'}
    >
      <Box display="flex" alignItems="center" zIndex={99} backgroundColor="white">
        <Box pr="16px" borderRight={`1px solid ${Colors.secondary[200]}`} flex="1 0 auto">
          <Box display="flex" alignItems="center" mb="2px">
            <Box width="8px" height="8px" backgroundColor={Colors.secondary.default} borderRadius="3px" mr="8px" />
            <Box>
              <IonText>
                <h5 style={{ color: Colors.secondary.default, margin: 0 }}>{kindMap[announcement.kind]}</h5>
              </IonText>
            </Box>
          </Box>
          <Box textAlign="right">
            <IonText>
              <h4 style={{ color: Colors.secondary[300], margin: 0 }}>
                {format(announcement.publishAt * 1000, 'yyyy.MM.dd')}
              </h4>
            </IonText>
          </Box>
        </Box>
        <Box px="16px" width="100%">
          <IonText>
            <h4 style={{ color: Colors.secondary[1000], fontWeight: 'bold' }}>{announcement.title}</h4>
          </IonText>
        </Box>

        {!defaultOpen && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={handleToggleAccordion}
          >
            <IonIcon
              icon={chevronDown}
              style={{
                fontSize: '27px',
                color: Colors.secondary[200],
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: '256ms'
              }}
            />
          </Box>
        )}
      </Box>
      <Box overflow="hidden">
        <div
          style={{
            transition: '256ms',
            transform: open ? 'translateY(0)' : 'translateY(-100%)',
            opacity: open ? '100' : '0',
            marginTop: open ? '13px' : '-100%',
            borderTop: `1px solid ${Colors.secondary[50]}`,
            maxHeight: open ? '100%' : '0px',
            zIndex: 1
          }}
        >
          <StyledMarkdown>
            <Markdown content={announcement.description} />
          </StyledMarkdown>
        </div>
      </Box>
    </Box>
  )
}
