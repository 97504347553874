import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonFabButton,
  isPlatform
} from '@ionic/react'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import {
  home,
  person,
  calendar,
  megaphone,
  // clipboard,
  close,
  mail,
  // document as documentIcon,
  ribbon
} from 'ionicons/icons'
import styled from 'styled-components'
import { Box, Colors } from '../../atoms'
import { useSafeAreaInset } from '../../../libs'

interface AppPage {
  url: string
  iosIcon: string
  mdIcon: string
  title: string
  blank?: boolean
}

const appPages: AppPage[] = [
  {
    title: 'ホーム',
    url: '/',
    iosIcon: home,
    mdIcon: home
  },
  {
    title: 'マイページ',
    url: '/mypage',
    iosIcon: person,
    mdIcon: person
  },
  {
    title: 'カレンダー',
    url: '/schedule',
    iosIcon: calendar,
    mdIcon: calendar
  },
  {
    title: 'お知らせ',
    url: '/notification',
    iosIcon: megaphone,
    mdIcon: megaphone
  },
  // {
  //   title: '利用規約',
  //   url: '/term-of-service',
  //   iosIcon: clipboard,
  //   mdIcon: clipboard
  // },
  // {
  //   title: '特定商取引',
  //   url: '/specified-commercial-transaction',
  //   iosIcon: documentIcon,
  //   mdIcon: documentIcon
  // },
  {
    title: '特典動画',
    url: '/special-movie',
    iosIcon: ribbon,
    mdIcon: ribbon
  },
  {
    title: 'お問い合わせ',
    url: 'https://55auto.biz/openplatform/touroku/cwc_opinion.htm',
    blank: true,
    iosIcon: mail,
    mdIcon: mail
  }
]

const StyledMenu = styled(IonMenu)`
  --background: ${Colors.primary.default};
`

const StyledContent = styled(IonContent)`
  --background: ${Colors.primary.default};
`

const StyledMenuList = styled(IonList)`
  background-color: ${Colors.primary.default};
  padding-top: 56px;
`

const StyledMenuItem = styled(IonItem)`
  --color: white;
  --background: ${Colors.primary.default};
  --background-hover: black;
  &.selected {
    --background: #f5494d;
  }
`

const StyledIcon = styled(IonIcon)`
  color: white !important;
  font-size: 22px;
  margin-right: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const StyledCloseButton = styled(IonFabButton)`
  --box-shadow: none;
  --background: transparent;
  --background-hover: black;
  --width: 20px;
  --height: 20px;
`

const StyledLabel = styled(IonLabel)`
  color: white !important;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
`

// const handleClickMail = (user?: IUser) => {
//   const sendTo = 'info@ikedatakamasa.com'
//   const subject = encodeURIComponent('件名を入力してください')
//   const body = encodeURIComponent(
//     `【お問い合わせ内容】\n
//   こちらにお問い合わせ内容を記入してください \n
//   \n
//   \n
//   \n
//   ======== 以下は編集しないでください ========
//   ユーザー名: ${user?.displayName}
//   ユーザーID: ${user?.id}`
//   )

//   const anchor = document.createElement('a') as HTMLAnchorElement
//   anchor.href = `mailto:info@${sendTo}?subject=${subject}&body=${body}`
//   anchor.target = '_blank'
//   anchor.click()
// }

export const Menu = () => {
  const location = useLocation()
  const { value: topInset } = useSafeAreaInset('top')
  // const { user } = useGetMe()
  const disabledMenu = useMemo(() => {
    const { pathname } = location
    return pathname === '/login' || pathname === '/password-reset'
  }, [location])

  return (
    <StyledMenu contentId="main-content" menuId="main-content" type="overlay" side="end" disabled={disabledMenu}>
      <StyledContent style={{ position: 'relative' }}>
        {isPlatform('capacitor') && <Box width="20px" height={`${topInset + 30}px`} />}
        <StyledMenuList mode="md" id="inbox-list" lines="none">
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle style={{ position: 'relative' }} key={index} autoHide={false}>
                {location.pathname === appPage.url && (
                  <Box
                    width="8px"
                    height="54px"
                    backgroundColor="white"
                    position="absolute"
                    top="0px"
                    left="0px"
                    zIndex={99}
                  />
                )}
                <StyledMenuItem
                  className={location.pathname === appPage.url ? 'selected' : ''}
                  href={appPage.url}
                  routerLink={appPage.blank ? undefined : appPage.url}
                  routerDirection="forward"
                  routerOptions={{ unmount: true }}
                  target={appPage.blank ? '_blank' : undefined}
                  lines="none"
                  detail={false}
                >
                  {/* https://github.com/ionic-team/ionic-framework/issues/21939#issuecomment-679936379 */}
                  <div tabIndex={0} style={{ display: 'flex', alignItems: 'center', outline: 'none' }}>
                    <StyledIcon
                      className={location.pathname === appPage.url ? 'selected' : ''}
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <StyledLabel className={location.pathname === appPage.url ? 'selected' : ''}>
                      {appPage.title}
                    </StyledLabel>
                  </div>
                </StyledMenuItem>
              </IonMenuToggle>
            )
          })}
        </StyledMenuList>

        {
          <IonMenuToggle
            style={{
              position: 'absolute',
              top: isPlatform('capacitor') ? topInset + 30 + 'px' : '0px',
              right: 0,

              cursor: 'pointer',
              zIndex: 999
            }}
          >
            <StyledCloseButton size="small">
              <IonIcon icon={close} size="large"></IonIcon>
            </StyledCloseButton>
          </IonMenuToggle>
        }
      </StyledContent>
    </StyledMenu>
  )
}
