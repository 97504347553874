import { IonButton } from '@ionic/react'
import styled from 'styled-components'

export const Button = styled(IonButton)`
  --border-radius: 20px;
  text-transform: none;
  font-family: 'SF Pro Display', 'Hiragino Kaku Gothic ProN', 'メイリオ', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
`
