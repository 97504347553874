/* eslint-disable no-irregular-whitespace */
import React from 'react'
import { Box, Colors } from '../../atoms'
import { IonText } from '@ionic/react'
import { Markdown } from '../../atoms/MarkdownParser'
import styled from 'styled-components'

export interface PageTermsOfServiceProps {}
const StyledMarkdown = styled.div`
  > * {
    color: ${Colors.secondary.default};
  }
  > h1 {
    font-size: 40px;
  }
  > h2 {
    font-size: 26px;
  }
  > h3 {
    font-size: 20px;
    font-weight: bold;
  }

  > p {
    /* display: flex; */
    font-size: 14px;
    white-space: pre-wrap;
    word-break: break-all;
    margin: 12px 0px;
    line-height: 1.2;
  }
  > p img {
    max-width: 200px;
    object-fit: cover;
    cursor: pointer;
  }
  > p a {
    color: ${Colors.primary.default};
  }
  > blockquote {
    color: ${Colors.secondary[300]};
    padding: 0px;
    padding-left: 12px;
    margin: 0px;
    border-left: 3px solid ${Colors.secondary[300]};
    word-break: break-all;
    white-space: pre-wrap;
  }

  > p code {
    display: inline-block;
    align-items: center;
    padding: 1px 8px;
    margin: 0px 2px;
    background-color: black;
    font-size: 12px;
    color: white;
  }

  ul {
    margin-left: -20px;
    margin-bottom: 8px;
  }
`

const content = `
## 第１条（適用）

1. オープンプラットフォーム会員約款（以下「本約款」という。）は、株式会社オープンプラットフォーム（以下「当社」という。）が実施する会員サービス（以下「本サービス」という。）に関する当社と会員との間の会員契約（以下「本契約」という。）に適用される。
2. 会員が、会員サービスとは別に当社が実施する各種セミナーを受講する場合は、当該セミナーの受講に係る契約に関しては、当社が別途定めるオープンプラットフォームセミナー約款（以下「セミナー約款」という。）が適用されるものとする。また、本サービスの内容として、本サービス固有のセミナーが実施される場合、当該セミナーに関する契約条件は、セミナー約款が準用される。 

## 第２条（申込）

1. 会員は、本約款の全ての内容に同意した上で、当社所定の方法により、本契約の申込みを行うものとする。会員は、申込の際に当社に登録する事項（以下「登録事項」という。）が、全て正確であることを保証する。
2. 当社は、当社所定の基準により申込みの可否を判断し、申込みを承諾する場合には、その旨通知する。当該通知に基づき、当社と会員との間で、本約款に定める内容で、本契約が成立する。 
3. 当社は、会員が以下のいずれかに該当した場合は、会員の申込みを承諾しないことがある。なお、当社は、上記判断に関する理由を開示する義務は負わない。<br />
  （１）当社所定の方法によらずに本契約の申込を行った場合<br />
  （２）登録事項の全部又は一部につき、虚偽、誤記又は記載漏れがあった場合<br />
  （３）本約款に違反するおそれがある場合<br />
  （４）過去に本約款に違反した者又はその関係者である場合<br />
  （５）本サービスと同種又は類似するサービスを現に提供し又は将来提供する予定である場合<br />
  （６）未成年者であり、かつ親権者の同意がない場合<br />
  （７）その他当社が申込を妥当でないと判断した場合
4. 会員は、申込時の登録事項に変更が生じた場合は、直ちに当社に対し、その旨書面により通知するものとする。これを怠ったことによって会員が損害を被ったとしても、当社は一切責任を負わない。

## 第３条（会費）

1. 会員は、当社に対し、本サービスの対価として、当社所定の入会金及び月額会費（以下合わせて「会費」という。）を支払うものとする。
2. 前項の会費は、当社所定の期限までに、クレジットカード決済又は当社所定の銀行口座宛に振込送金の方法により、支払うものとする。なお、振込手数料は、会員の負担とする。
3. 会員が会費の全部又は一部を支払わない場合、当社は会員に対し、支払期限の翌日より実際の支払日までの日数に応じ、未払会費に対し年利１４．６％を乗じて計算した金額を、遅延損害金として請求できる。

## 第４条（退会）

1. 会員は、本契約期間中といえども、退会希望月の末日までに、当社に対して通知することにより、退会希望月の末日限りで、本契約を解約して退会することができる。
2. 当社が、前項の退会月の翌月以降に係る月額会費を既に受領している場合、当社は会員に対して、遅滞なく受領済みの退会月の翌月以降に係る月額会費を返還するものとする（入会金の返還は行わないものとする。）。なお、振込手数料その他返還に要する費用は、会員の負担とし、当該費用を差し引いた金額を、会員に返還するものとする。

## 第５条（本サービス）

1. 当社は、当社が別途定める内容にて、本サービスを実施するものとする。当社は、本サービスの内容の追加、削除等の変更を行うことがあり、会員は、これを予め承諾するものとする。
2. 当社は、本サービスに関して、体質、能力、技能、売上及び収益等の変化又は向上、その他会員の特定の目的への適合を保証するものではなく、あくまでも当社の保有するノウハウを学ぶ場を提供するものであることを、会員は理解するものとする。当社は、善良なる管理者の注意義務に従って本サービスを実施する限りの責任を負うものとし、会員は、本サービスで学んだ当社のノウハウを参考に、自己の責任の下で問題解決に取り組むものとする。会員は、当社による債務不履行を主張する場合は、当社の善管注意義務違反に該当する行為を、具体的に主張立証する必要がある。
3. 当社は、当社の責任において、本サービスの一部を第三者に再委託できる。当社は、再委託先に対して、本契約に基づく自己の義務と同内容の義務を負わせるものとし、再委託先の行為に関して、会員の責めに帰すべき事由がある場合を除き、自ら本サービスを実施した場合と同様の責任を負うものとする。

## 第６条（本特典）

1. 当社は、本サービスの実施にあたり、会員に対し、当社所定の特典（以下「本特典」という。）を提供するものとする。
2. 本特典は、郵送その他当社所定の方法により、会員に提供するものとする。
3. 会員は、本特典に関し、著作権法第３０条に定める私的使用のための複製以外は、著作権法上の利用（複製、翻案、配布、上映、口述、自動公衆送信及びそのために必要な送信可能化を含み、かつこれに限られない。）を行うことができない。また、本特典を、第三者に販売、貸与、譲渡、開示、閲覧させてはならない。
4. 会員が本契約に違反した場合、会員は直ちに本特典を当社に返還するものとする。なお、返還に要する費用は、会員の負担とする。

## 第７条（本記録物）

1. 会員は、当社が事前に書面で許諾した場合を除き、本サービス（全部又は一部を問わない。）を撮影又は録音してはならない。
2. 本サービス（全部又は一部を問わない。）を撮影、録音又は書き起こしたテキスト（以下「本記録物」という。）に関しては、前条第３項及び第４項を準用する。

## 第８条（権利帰属）

1. 本サービスに関する一切の著作権は、全て当社に帰属する。
2. 本契約の締結は、本約款に明示的に規定される場合を除き、本サービスに関する当社の著作権に関し、いかなる権利も許諾するものではない。

## 第９条（禁止行為）

会員は、以下の各号のいずれかに該当し又は該当すると当社が判断する行為をしてはならない。<br />
（１）第５条（本サービス）第２項に違反する行為<br />
（２）第６条（本特典）第３項に違反する行為<br />
（３）第７条（本記録物）に違反する行為<br />
（４）本サービスの運営・維持を妨げる行為<br />
（５）本サービスの信用を毀損する行為<br />
（６）他の会員又は第三者になりすます行為<br />
（７）第三者に本サービスを利用させる行為<br />
（８）本サービス内で宣伝、広告、勧誘又は営業をする行為<br />
（９）他の会員の情報を収集する行為<br />
（10）法令に違反する行為<br />
（11）犯罪に関連する行為<br />
（12）公序良俗に反する行為<br />
（13）所属する業界団体の内部規則に違反する行為<br />
（14）当社、他の会員又は第三者の知的財産権等、プライバシー権、名誉権、信用、肖像権、その他一切の権利又は利益を侵害する行為<br />
（15）前各号の行為を直接又は間接に惹起し又は容易にする行為<br />
（16）その他、当社が不適切と判断する行為

## 第１０条（個人情報）

当社は、会員の個人情報を、当社所定の「プライバシーポリシー」に基づき、適切に取り扱うものとする。

## 第１１条（秘密保持）

会員は、本サービスに関連して当社が会員に対して秘密に扱うことを指定して開示した情報について、当社の事前の書面による承諾がある場合を除き、開示目的以外に利用せず、また、第三者に開示しないものとする。

## 第１２条（不可抗力）

1. 当社は、天災地変、交通機関の遅延又は不通、偶発的事故、停電、通信障害、事業上の理由、その他のやむを得ない事由がある場合は、本サービスをいつでも変更又は中止することができるものとし、これによって会員に生じたいかなる損害についても、一切責任を負わないものとする。
2. 前項の場合、当社は、会員に対し、会費の返還を行わないものとする。

## 第１３条（期限の利益喪失・登録抹消）

1. 会員が本契約に違反し、当社が相当期間を定めて催告したにも拘わらず是正されない場合には、会員の本契約上の債務は期限の利益を失い、当社は、直ちに本契約を解除して会員登録を抹消することができる。
2. 会員が次の各号の一に該当した場合には、何らの催告なくして、会員の本契約上の債務は期限の利益を失い、当社は、直ちに本契約を解除して会員登録を抹消することができる。<br />
（１）支払停止、支払不能に陥った場合<br />
（２）自ら振り出しもしくは裏書した手形、小切手の不渡りを１回でも出した場合<br />
（３）差押え、仮差押え、仮処分、競売の申立て、公租公課の滞納処分その他公権力の処分を受けた場合<br />
（４）破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始の申立てを受け、またはなした場合<br />
（５）その他信用状態が悪化した場合<br />
（６）解散又は事業の全部若しくは重要な部分の譲渡決議をした場合<br />
（７）事業を廃止した場合<br />
（８）監督官庁より事業停止命令を受け、または事業に必要な許認可の取消処分を受けた場合<br />
（９）その他事業の継続が困難になった場合<br />
（10）株主構成、役員の変動等により会社の実質的支配関係が変化し従前の会社との同一性が失われた場合<br />
（11）当社に対する重大な背信行為があった場合<br />
（12）第９条（禁止行為）に違反した場合<br />
（13）当社からの問い合わせに対して、１ヶ月以上応答がない場合<br />
（14）その他、当社が本サービスの利用を適当でないと判断した場合
3. 当社が本条による解除を行う場合でも、会員は本契約期間満了までの会費の支払義務を免れないものとする（会員が既に会費を支払っている場合、当社はこれを会員に返還しないものとする。）。

## 第１４条（反社会的勢力との関係排除）

1. 本条において「反社会的勢力」とは、次の各号のいずれかに該当する者をいう。
（１）暴力団及びその関係団体又はその構成員
（２）暴力、威力又は詐欺的手法を駆使して経済的利益を追求する団体又は個人
（３）その他、前各号の該当者に準ずる者
2. 会員は、次の各号に定める内容について、表明し、保証する。
（１）自らが反社会的勢力に該当せず、かつ将来に渡っても該当しないこと
（２）自らが反社会的勢力と不適当な関係を有さず、かつ将来に渡っても不適当な関係を有しないこと
3. 会員が前項に違反したことが判明した場合には、何らの催告なくして、会員の本契約上の債務は期限の利益を失い、当社は、直ちに本契約を解除することができる。なお、その場合の会費の取り扱いは、前条（期限の利益喪失・解除）第３項を準用する。

## 第１５条（紛争処理及び損害賠償）

1. 会員は、本サービスの利用に関連して当社に損害を与えた場合、当社に対し、その損害を賠償するものとする。
2. 会員が、本サービスの利用に関連して第三者からクレームを受け又は第三者との間で紛争が生じた場合、会員は、直ちにその内容を当社に通知すると共に、会員の費用と責任において、当該クレーム又は紛争を処理し、その進捗及び結果を当社に報告するものとする。
3. 当社が、会員による本サービスの利用に関連して第三者からクレームを受け又は第三者との間で紛争が生じた場合、会員は、会員の費用と責任において、当該クレーム又は紛争を処理し、その進捗及び結果を当社に報告すると共に、当社が支払いを余儀なくされた金額その他の損害を賠償するものとする。
4. 当社は、本サービスの実施に際して、自己の故意又は重過失により会員に損害を与えた場合についてのみこれを賠償するものとする。本約款における当社の各免責規定は、当社に故意又は重過失が存する場合には適用しないものとする。
5. 当社が会員に対して損害賠償義務を負う場合（前項の場合又は消費者契約法その他の法律の適用による場合等）、賠償すべき損害の範囲は、会員に現実に発生した通常の損害に限る（逸失利益を含む特別の損害は含まない。）ものとし、賠償すべき損害の額は、当該損害発生の直接の原因が生じた月の月額会費を限度とする。なお、本条は、債務不履行、瑕疵担保責任、原状回復義務、不当利得、不法行為その他請求原因を問わず、全ての損害賠償等に適用されるものとする。

## 第１６条（契約期限）

1. 本契約の期間は、本契約締結日から６ヶ月間とする。
2. 本契約の有効期間満了日の前日までに、当社又は会員から本契約を更新しない旨の通知がない限り、本契約はさらに６ヶ月間更新されるものとし、以降も同様とする。

## 第１７条（本約款の変更）

1. 当社は、その理由を問わず本約款をいつでも任意に変更することができるものとし、会員はこれに同意する。
2. 当社が別途定める場合を除き、本約款の変更は、当社ウェブサイトに掲載する方法によって会員へ通知する。
3. 本約款の変更は、前項の通知において指定した日付より効力を生じるものとする。
4. 当社は、会費等の重要事項を変更する場合は、前項の指定した日付までに相応の期間をもって、前項の通知を行うよう努めるものとする。
5. 会員が本約款の変更を同意しない場合、会員の唯一の対処方法は、本契約を解約するのみとなる。会員が第２項の通知において指定した日付までに本契約を解約しない場合、本約款の変更に同意したものとみなされる。

## 第１８条（連絡）

1. 当社から会員への連絡は、書面の送付、電子メールの送信、または当社ウェブサイトへの掲載等、当社が適当と判断する手段によって行う。当該連絡が、電子メールの送信又は当社ウェブサイトへの掲載によって行われる場合は、インターネット上に配信された時点で会員に到達したものとする。
2. 会員から当社への連絡は、当社所定の問合せフォームから、又は問合せ用メールアドレス若しくは問合せ用電話番号宛に行うものとする。当社は、問合せフォーム又は問合せ用メールアドレス若しくは問合せ用電話番号以外からの問い合わせについては、対応は行わないものとする。

## 第１９条（権利義務の譲渡）

1. 当社は、会員に対する債権を第三者に譲渡できるものとし、会員は、そのために会員の個人情報等が当該第三者に提供されることを承諾するものとする。
2. 会員は、当社の事前の書面による承諾なく、本契約上の地位又は本契約に基づく権利義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできないものとする。

## 第２０条（完全合意）

本約款は、本契約に関連する当社及び会員の完全なる合意を構成し、本契約の締結以前に当社及び会員間でなされた本契約に関連するいかなる口頭又は書面による合意も、全て本約款に取って代わられる。

## 第２１条（分離可能性）

本約款の規定の一部が、法令又は裁判所により違法、無効又は不能であるとされた場合においても、当該規定のその他の部分及び本約款のその他の規定は、有効に存続する。

## 第２２条（存続条項）

本契約の終了後といえども、第２条（申込）第４項、第３条（受講料）第３項、第５条（本サービス）第２項、第６条（本特典）第３項及び第４項、第７条（本記録物）第２項、第８条（権利帰属）、第９条（禁止行為）、第１１条（秘密保持）、第１５条（紛争処理及び損害賠償）、第１７条（本約款の変更）、第１８条（連絡）、第１９条（権利義務の譲渡）、第２０条（完全合意）、第２１条（分離可能性）、本条（存続条項）及び第２３条（合意管轄）並びにその他各規定の趣旨に照らし当然に存続する権利及び義務は、なお有効に存続する。

## 第２３条（合意管轄）

本契約に関して紛争が生じた場合、訴額に応じて、東京地方裁判所又は東京簡易裁判所をもって、第一審の専属的合意管轄裁判所とする。 以上 
`

export const PageTermsOfService = (_: PageTermsOfServiceProps) => {
  return (
    <Box width="100%" maxWidth="720px" pb="80px" backgroundColor="white" borderRadius="12px" py="102px" px="40px">
      <Box>
        <IonText>
          <h2
            style={{
              fontSize: '20px',
              color: Colors.secondary.default,
              margin: 0,
              marginBottom: '24px',
              textAlign: 'center'
            }}
          >
            オープンプラットフォーム会員約款
          </h2>

          <StyledMarkdown>
            <Markdown content={content} />
          </StyledMarkdown>
        </IonText>
      </Box>
    </Box>
  )
}
