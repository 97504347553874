import React from 'react'

import { Box, Colors } from '../../atoms'
import { Login as OrgLogin } from '../../organisms'
import { Layout } from '../../Layout'

export const Login = () => {
  return (
    <Layout disableTermSelectbox={true}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor={Colors.bg.default}
      >
        <OrgLogin />
      </Box>
    </Layout>
  )
}
