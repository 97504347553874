import React from 'react'
import { Browser } from '@capacitor/browser'

interface Props {
  href: string
  children: React.ReactNode
}

export const MarkdownItemLink = (props: Props) => {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noreferrer noopener"
      onClick={e => {
        e.preventDefault()
        const href = e.currentTarget.href
        Browser.open({ url: href })
      }}
    >
      {props.children}
    </a>
  )
}
