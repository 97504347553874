export const Colors = {
  primary: {
    default: '#DE4346'
  },
  secondary: {
    default: '#2A2A2D',
    1000: '#2A2A2D',
    800: '#343438',
    600: '#72727A',
    300: '#BABAC7',
    200: '#D1D1DE',
    50: '#F0F0FF'
  },
  teritary: {
    default: '#29CC97'
  },
  success: {
    default: '#DE4346'
  },
  danger: {
    default: '#DE4346'
  },
  medium: {
    default: '#72727A'
  },
  light: {
    default: '#D1D1DE'
  },
  dark: {
    default: '#2A2A2D'
  },
  bg: {
    default: '#F5F6F8'
  }
}
