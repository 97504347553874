import { axios } from '../../libs'
import { IVideo, ITerm } from '@open-platform/interface/src/entity'
import useSWR, { mutate } from 'swr'
import {
  ListVideosResponse,
  ListVideosRequest,
  GetVideoRequest,
  GetVideoResponse,
  ListTermsResponse
} from '@open-platform/interface/src/request'
import { SWRCachePath } from '../swr-cache-path'
import { IDownload } from '../download/interface'
import { useEffect } from 'react'
import { isPlatform } from '@ionic/react'
import { CapacitorDataStorageSqlite } from 'capacitor-data-storage-sqlite'
import { capStorageOpts, storageOptions } from '../download/hooks'
const storage = isPlatform('capacitor') ? CapacitorDataStorageSqlite : undefined

export const useGetVideoList = ({ termId }: ListVideosRequest['query']) => {
  const { data, error } = useSWR<IVideo[]>(SWRCachePath.VIDEO_LIST + termId, () =>
    axios
      .get<ListVideosResponse>('/videos', {
        params: {
          termId
        }
      })
      .then<IVideo[]>(res => {
        const data = res.data
        return data.videos
      })
  )
  return {
    videos: data || [],
    error,
    isLoading: !data && !error
  }
}

export interface IPlayList {
  id: string
  title: string
  filePath?: string
  url?: string
  album?: string
}

const createVideoStreamUrl = (video: IVideo) => process.env.REACT_APP_GCS_URL + video.path

const getPlaylist = async (videos: IVideo[]) => {
  await storage?.openStore(storageOptions)
  const isOpen = await storage?.isStoreOpen(capStorageOpts)
  if (isOpen) {
    const res = await storage?.values()
    const downloadList = res?.values.map(value => JSON.parse(value)) as IDownload[]
    const l = videos.map(v => {
      const item: IPlayList = {
        id: v.id,
        title: v.title,
        album: ''
      }
      const downloadVideo = downloadList.find(dv => dv.id === v.id)
      // ダウンロードしてたビデオがあれば filePath を入れる
      if (downloadVideo) {
        item['filePath'] = downloadVideo.filePath
      } else {
        item['url'] = createVideoStreamUrl(v)
      }

      return item
    })
    console.log(l)
    return l
  } else {
    return videos.map(v => ({ id: v.id, title: v.title, url: createVideoStreamUrl(v), album: '' }))
  }
}
export const useGetCurrentTermVideoPlayList = ({ termId }: { termId: string }) => {
  const { videos } = useGetVideoList({ termId })
  const { data, error } = useSWR<IPlayList[]>(SWRCachePath.listNativePlayerPlaylist(termId), null, {
    fetcher: undefined,
    onError: err => console.log(err)
  })

  useEffect(() => {
    mutate(SWRCachePath.listNativePlayerPlaylist(termId), getPlaylist(videos), false)
  }, [termId, videos])

  return {
    playlist: data || [],
    error,
    isLoading: !data && !error
  }
}

export const useGetVideo = ({ videoId }: GetVideoRequest['param']) => {
  const { data, error } = useSWR<IVideo>(SWRCachePath.getVideo({ videoId }), () =>
    axios.get<GetVideoResponse>(`/videos/${videoId}`).then<IVideo>(res => {
      const data = res.data
      return data.video
    })
  )
  return {
    video: data,
    error,
    isLoading: !data && !error
  }
}

// 最新のvideoを取得
export const useGetLatestVideo = () => {
  const { data, error } = useSWR<IVideo>(SWRCachePath.VIDEO_LATEST, async () => {
    const terms = await axios
      .get<ListTermsResponse>(`/terms`)
      .then<ITerm[]>(res => res.data.terms.filter(term => term.period !== '999999'))
    const latestTerm = terms[0]
    return axios
      .get<ListVideosResponse>('/videos', {
        params: {
          termId: latestTerm.id
        }
      })
      .then<IVideo>(res => {
        const data = res.data
        return data.videos[0]
      })
  })
  return {
    video: data,
    error,
    isLoading: !data && !error
  }
}

// 特典動画を取得する
export const useGetSpecialVideoList = () => {
  const { data, error } = useSWR<IVideo[]>('/videos/special', async () => {
    const terms = await axios.get<ListTermsResponse>(`/terms`).then(res => res.data.terms)
    const specialTerm = terms.find(term => term.period === '999999')
    if (specialTerm) {
      return axios
        .get<ListVideosResponse>('/videos', {
          params: {
            termId: specialTerm.id
          }
        })
        .then<IVideo[]>(res => {
          const data = res.data
          return data.videos
        })
    }

    return []
  })
  return {
    videos: data,
    error,
    isLoading: !data && !error
  }
}
