import React, { useState, useEffect, useRef, useCallback } from 'react'

import { Box, Tag, Colors } from '../../atoms'
import { IonIcon, IonText, IonTextarea, IonAlert, useIonRouter, IonRippleEffect, isPlatform } from '@ionic/react'
import { Button } from '../../atoms/Button'
import { downloadOutline, pauseSharp, playSharp, playBack, playForward } from 'ionicons/icons'
import { CommentList } from '../../organisms/CommentList'
import { IVideoComment, IVideo } from '@open-platform/interface/src/entity'
import './MainContent.css'
import { convertPeriodToDisplayString } from '../../../libs'
import Hls from 'hls.js'
import { useCreateVideoComment } from '../../../modules/videoComment'
import { PlaybackRateSelect } from '../PlaybackRateSelect'
import { useMediaQuery } from 'react-responsive'
import { SP_MAX_WIDTH } from '../../../setting'
import { useMemo } from 'react'
import { useDownloadFile } from '../../../modules/utils'
import { useGetCurrentTermVideoPlayList, useGetVideoList } from '../../../modules/video'
import { FavoriteButton } from './FavoriteButton'
import { DownloadStatus, useDownload } from '../../../modules/download/hooks'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { NativeVideoPlayer } from './NativeVideoPlayer'
import { mutate } from 'swr'
import { SWRCachePath } from '../../../modules/swr-cache-path'
import { Plugins } from '@capacitor/core'
import styled from 'styled-components'
import { Markdown } from '../../atoms/MarkdownParser'
import { Browser } from '@capacitor/browser'

const VIDEO_SPEED_RATE_KEY = 'videoSpeedRate'
type Size = number | string
export interface MainContentCardProps {
  width?: Size
  height?: Size
  videoHeight?: Size
  video: IVideo
  videoCommentList?: IVideoComment[]
}

const getStatusIcon = (status?: DownloadStatus) => {
  if (status) {
    switch (status) {
      case 'waiting':
        return downloadOutline
      case 'downloading':
        return pauseSharp
      case 'pause':
        return downloadOutline
      case 'completed':
        return playSharp
      case 'failed':
        return downloadOutline
      default:
        return
    }
  }
}

const StyledMarkdown = styled.div`
  > * {
    color: ${Colors.secondary.default};
  }

  > p {
    font-size: 12px;
    white-space: pre-wrap;
    word-break: break-all;
    margin: 12px 0px;
    line-height: 1.2;
  }
  > p a {
    color: ${Colors.secondary.default};
  }
`

export const MainContentCard = ({ width = '100%', height = '100%', video }: MainContentCardProps) => {
  const [text, setText] = useState<string>()
  const [isPrevCommentLoading, setIsPrevCommentLoading] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const { handleDownloadFile } = useDownloadFile()
  const commentHooks = useCreateVideoComment()
  const { handleCreateVideoComment } = commentHooks
  const isCreateCommentLoading = commentHooks.isLoading
  const videoRef = useRef<HTMLVideoElement>(null)
  const [playBackRate, setPlayBackRate] = useState(1.0)

  console.log(video.term.id)
  // setup video
  const { videos } = useGetVideoList({ termId: video.term.id })
  const router = useIonRouter()
  const { handleCreateDownload, handlePauseDownload, handleResumeDownload, downloadStatus } = useDownload(video.id)
  const { playlist } = useGetCurrentTermVideoPlayList({ termId: video.term.id })

  // 次に再生する動画取得
  const nextVideo = useMemo(() => {
    if (videos) {
      const currentVideoIndex = videos.findIndex(v => v.id === video.id)
      const nextIndex = currentVideoIndex + 1
      if (nextIndex <= videos.length) {
        return videos[nextIndex]
      } else {
        return videos[0]
      }
    }
  }, [videos, video])

  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })

  const isIos = useMemo(() => {
    return ['iPhone', 'iPad', 'iPod'].some(item => window.navigator.userAgent.indexOf(item) !== -1)
  }, [])

  const handleDownloadButtonClick = useCallback(() => {
    if (downloadStatus) {
      switch (downloadStatus.status) {
        case 'waiting':
          if (window.confirm('ダウンロードを開始しますか？')) handleCreateDownload(video)
          break
        case 'downloading':
          if (window.confirm('ダウンロードを一時停止しますか？')) handlePauseDownload()
          break
        case 'pause':
          if (window.confirm('ダウンロードを再開しますか？')) handleResumeDownload()
          break
        case 'completed': {
          const nvp = isPlatform('ios') ? Plugins.NvpPlugin : Plugins.Nvp
          const idx = playlist.findIndex(item => item.id === video.id)
          if (idx === -1) return
          const list = playlist.slice(idx)
          nvp.start({
            playlist: list
          })
          break
        }

        default:
          return
      }
    } else {
      if (window.confirm('ダウンロードを開始しますか？')) handleCreateDownload(video)
    }
  }, [downloadStatus, handleCreateDownload, handlePauseDownload, handleResumeDownload, playlist, video])

  // sample hls
  useEffect(() => {
    if (isPlatform('capacitor')) mutate(SWRCachePath.getDownloadInfo(video.id))
    const hls = new Hls()
    if (videoRef.current) {
      if (video.path.split('.')[video.path.split('.').length - 1] === 'm3u8') {
        const src = process.env.REACT_APP_GCS_URL + video.path
        if (isIos) {
          videoRef.current.src = src
        } else {
          hls.loadSource(src)
          hls.attachMedia(videoRef.current)
        }
      } else {
        videoRef.current.src = video.path
        videoRef.current.play()
      }
    }

    return () => {
      hls.destroy()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      videoRef.current?.pause()
    }
  }, [video, video.id, video.path, isIos])

  // playback rate の更新
  useEffect(() => {
    if (videoRef.current) videoRef.current.playbackRate = playBackRate
  }, [playBackRate])

  // for auto play next video
  useEffect(() => {
    const handleEndedVideo = () => {
      if (nextVideo) {
        router.push(`/videos/${nextVideo.id}`)
      }
    }
    videoRef.current?.removeEventListener('ended', handleEndedVideo)
    videoRef.current?.addEventListener('ended', handleEndedVideo)
  }, [nextVideo, router])

  // コメントの送信完了時
  useEffect(() => {
    if (isPrevCommentLoading && !isCreateCommentLoading) {
      setText(undefined)
      setIsPrevCommentLoading(false)
      setOpenAlert(true)
    }
  }, [isPrevCommentLoading, isCreateCommentLoading])

  const handleClickSendComment = useCallback(() => {
    if (text) {
      handleCreateVideoComment({ videoId: video.id, text })
      setIsPrevCommentLoading(true)
    }
  }, [handleCreateVideoComment, text, video.id])

  const handleDownloadAudio = useCallback(() => {
    if (video.audioPath) {
      handleDownloadFile({ fileName: `${video.title}.mp3`, url: process.env.REACT_APP_GCS_URL + video.audioPath })
    }
  }, [handleDownloadFile, video.audioPath, video.title])

  const handleOpenPdfURL = useCallback(() => {
    const pdfURL = video.term.textbookPath
      ? process.env.REACT_APP_GCS_URL + window.encodeURIComponent(video.term.textbookPath)
      : ''
    console.log({ pdfURL: pdfURL })
    Browser.open({ url: pdfURL })
  }, [video.term.textbookPath])

  useEffect(() => {
    const rate = window.localStorage.getItem(VIDEO_SPEED_RATE_KEY) || 1.0
    setPlayBackRate(Number(rate))
  }, [])

  const [timerOutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>()

  const [showController, setShowController] = useState(false)

  useEffect(() => {
    if (showController) {
      setTimeoutId(
        setTimeout(() => {
          setShowController(false)
        }, 3000)
      )
    }
  }, [showController])
  return (
    <>
      <Box
        width={width}
        height={height}
        overflow="hidden"
        borderRadius={isSp ? '0px' : '12px'}
        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
        backgroundColor="white"
        position="relative"
      >
        <div style={{ aspectRatio: '16 / 9' }}>
          {isPlatform('capacitor') ? (
            <NativeVideoPlayer video={video} />
          ) : (
            <Box
              width={'100%'}
              height={'100%'}
              onMouseOver={() => {
                setShowController(true)
              }}
              onMouseLeave={() => {
                setShowController(false)
                if (timerOutId) {
                  clearTimeout(timerOutId)
                  setTimeoutId(undefined)
                }
              }}
            >
              <Box width="100%" height="100%" position="relative">
                {showController && (
                  <Box
                    zIndex={999}
                    position="absolute"
                    top="0"
                    right="0"
                    left="0"
                    bottom="0"
                    // backgroundColor="rgba(0,0,0,0.7)"
                    display="flex"
                    alignItems="center"
                    pointerEvents="none"
                  >
                    <Box display="flex" justifyContent="space-around" width="100%">
                      <Box
                        backgroundColor={Colors.primary.default}
                        border={`3px solid white`}
                        boxShadow="0px 10px 10px rgba(0,0,0,1)"
                        width="80px"
                        height="80px"
                        borderRadius="50%"
                        cursor="pointer"
                        pointerEvents="auto"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          if (videoRef.current) {
                            const vi = videoRef.current
                            vi.currentTime = vi.currentTime - 10
                          }
                        }}
                      >
                        <Box color={'white'}>
                          <div>10</div>
                          <IonIcon
                            icon={playBack}
                            style={{ fontSize: '30px', marginLeft: '-10px', marginBottom: '12px' }}
                          />
                        </Box>
                      </Box>

                      <Box
                        backgroundColor={Colors.primary.default}
                        border={`3px solid white`}
                        boxShadow="0px 10px 10px rgba(0,0,0,1)"
                        width="80px"
                        height="80px"
                        borderRadius="50%"
                        cursor="pointer"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        pointerEvents="auto"
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                          if (videoRef.current) {
                            const vi = videoRef.current
                            vi.currentTime = vi.currentTime + 10
                          }
                        }}
                      >
                        <Box>
                          <div>10</div>
                          <IonIcon
                            icon={playForward}
                            style={{ fontSize: '30px', marginRight: '0px', marginBottom: '12px' }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                <video
                  ref={videoRef}
                  controls
                  style={{ width: '100%', height: '100%', objectFit: 'cover', outline: 'none' }}
                  autoPlay
                  playsInline
                />
              </Box>
            </Box>
          )}
        </div>
        <Box px={isSp ? '16px' : '24px'} py="20px">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box flex="0 1 auto">
                <Tag count={String(video.sortIndex)} content={convertPeriodToDisplayString(video.term.period)} />
              </Box>
            </Box>

            <Box display="flex" alignItems="center">
              {!isSp && (
                <Box display="flex" alignItems="center" mr="12px">
                  <Button color="primary" disabled={!video.audioPath} onClick={() => handleDownloadAudio()}>
                    <Box display="flex" alignItems="center">
                      <Box mr="7px">
                        <IonIcon icon={downloadOutline} style={{ fontSize: '20px' }} />
                      </Box>
                      <div>音声</div>
                    </Box>
                  </Button>
                </Box>
              )}

              {isPlatform('capacitor') && (
                <Box display="flex" alignItems="center" mr="12px">
                  <Button color="primary" disabled={!video.term.textbookPath} onClick={() => handleOpenPdfURL()}>
                    <Box display="flex" alignItems="center">
                      <Box mr="7px">
                        <IonIcon icon={downloadOutline} style={{ fontSize: '20px' }} />
                      </Box>
                      <div>PDF</div>
                    </Box>
                  </Button>
                </Box>
              )}

              {!isPlatform('capacitor') && (
                <Box display="flex" alignItems="center" mr="16px">
                  <PlaybackRateSelect
                    value={playBackRate}
                    onSelect={value => {
                      window.localStorage.setItem(VIDEO_SPEED_RATE_KEY, value.toString())
                      setPlayBackRate(value)
                    }}
                  />
                </Box>
              )}

              <Box cursor="pointer">
                <FavoriteButton video={video} />
              </Box>

              {isPlatform('capacitor') && video.downloadPath && isPlatform('ios') && (
                <div
                  className="ion-activatable"
                  style={{ position: 'relative', overflow: 'hidden', marginLeft: '8px' }}
                  onClick={() => handleDownloadButtonClick()}
                >
                  <Box width="43px" height="43px" zIndex={9999} position="relative">
                    <CircularProgressbar
                      value={downloadStatus ? downloadStatus.progress * 100 : 0}
                      strokeWidth={10}
                      styles={buildStyles({
                        pathColor: Colors.secondary.default
                      })}
                    />
                  </Box>
                  <Box
                    position="absolute"
                    top="0"
                    right="0"
                    bottom="0"
                    left="0"
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="50%"
                    backgroundColor={Colors.primary.default}
                  >
                    <IonIcon
                      icon={getStatusIcon(downloadStatus?.status)}
                      style={{ fontSize: '20px', color: 'white' }}
                    />
                  </Box>
                  <IonRippleEffect />
                </div>
              )}
            </Box>
          </Box>
          <Box mb={isSp ? '16px' : '32px'}>
            <IonText>
              <h1
                style={{
                  color: Colors.secondary[1000],
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  lineHeight: '150%'
                }}
              >
                {video?.title}
              </h1>
              <h4
                style={{
                  color: Colors.secondary[1000],
                  fontFamily: 'SF Pro Display',
                  fontStyle: 'normal',
                  margin: 0,
                  fontSize: '16px',
                  lineHeight: '150%'
                }}
              >
                <StyledMarkdown>
                  <Markdown content={video.description} />
                </StyledMarkdown>
              </h4>
            </IonText>
          </Box>

          <Box position="relative" mb={isSp ? '0px' : '48px'}>
            <IonTextarea
              rows={isSp ? 3 : 5}
              value={text}
              onIonChange={e => setText(e.detail.value!)}
              autoGrow={false}
              style={{
                fontSize: isSp ? '12px' : '14px',
                color: Colors.secondary[1000],
                padding: '11px 16px',
                borderRadius: '8px',
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)'
              }}
              placeholder="コメントする"
            />
            <Box position="absolute" bottom="12px" right="12px" zIndex={99}>
              <Button color="primary" onClick={() => handleClickSendComment()} disabled={false}>
                <Box display="flex" alignItems="center">
                  <div>送信</div>
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
        )
        <Box px="22px" pb="12px">
          <CommentList videoId={video.id} />
        </Box>
      </Box>
      <IonAlert
        mode="ios"
        isOpen={openAlert}
        onDidDismiss={() => setOpenAlert(false)}
        header={'コメントを送信しました'}
        buttons={['OK']}
      />
    </>
  )
}
