import { IonModal } from '@ionic/react'
import React, { useState } from 'react'
import { Box } from '../Box'

interface Props {
  alt: string
  src: string
}

export const MarkdownItemImage = (props: Props) => {
  const [open, setOpen] = useState(false)

  const handleClickImage = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <IonModal isOpen={open} onDidDismiss={handleClose}>
        <Box>
          <img style={{ width: '100%', objectFit: 'contain', height: '100%' }} src={props.src} alt="md-modal-img"></img>
        </Box>
      </IonModal>
      <img src={props.src} onClick={handleClickImage} alt="md-img" />
    </div>
  )
}
