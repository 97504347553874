import React from 'react'
import { Box, Tag } from '../../atoms'
import { IonText, IonIcon, IonRouterLink } from '@ionic/react'
import { Colors } from '../../atoms/Color/Colors'
import { play, pause } from 'ionicons/icons'
import { format } from 'date-fns'

type Size = number | string
export interface CourseListItemProps {
  width?: Size
  height?: Size
  id: string
  active?: boolean
  title: string
  index?: number
  tagName?: string
}

export const CourseListItem = ({
  width = '100%',
  height = '80px',
  active = false,
  title,
  tagName,
  index = 0,
  id
}: CourseListItemProps) => {
  return (
    <Box backgroundColor="white" boxShadow="0px 0px 8px rgba(0, 0, 0, 0.08)" borderRadius="12px" overflow="hidden">
      <IonRouterLink
        routerLink={`/videos/${id}`}
        routerDirection="forward"
        routerOptions={{ as: `/videos/${id}`, unmount: true }}
      >
        <Box
          display="flex"
          width={width}
          height={height}
          px="24px"
          py="16px"
          color={active ? 'white' : Colors.secondary.default}
          backgroundColor={active ? Colors.primary.default : 'white'}
          cursor="pointer"
          opacity={!active ? '0.4' : undefined}
        >
          <Box width="100%">
            <Box mb="8px">
              <Tag contrast={active} count={String(index)} content={tagName || format(Date.now(), 'yyyy年M月')} />
            </Box>
            <IonText>
              <h4 style={{ fontWeight: 'bold', margin: 0 }}>{title}</h4>
            </IonText>
          </Box>
          <Box display="flex" alignItems="center">
            <Box
              width="32px"
              height="32px"
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              backgroundColor={active ? 'white' : Colors.secondary.default}
            >
              <IonIcon
                icon={active ? pause : play}
                style={{ color: active ? Colors.primary.default : 'white' }}
              ></IonIcon>
            </Box>
          </Box>
        </Box>
      </IonRouterLink>
    </Box>
  )
}
