import { axios } from '../../libs'
import { IUser } from '@open-platform/interface/src/entity'
import useSWR from 'swr'
import { GetUserRequest, GetUserResponse } from '@open-platform/interface/src/request'
import { SWRCachePath } from '../swr-cache-path'

export const useGetUser = ({ userID }: GetUserRequest['param']) => {
  const { data, error } = useSWR<IUser>(SWRCachePath.USER + userID, () =>
    axios.get<GetUserResponse>(`/users/${userID}`).then<IUser>(res => res.data.user)
  )
  return {
    user: data,
    error,
    isLoading: !data && !error
  }
}
