import React from 'react'
import { Layout } from '../../Layout'
import { Box, Colors } from '../../atoms'
import { RouteComponentProps } from 'react-router'
import { PageTermsOfService } from '../../organisms/PageTermsOfService'
import { useMediaQuery } from 'react-responsive'

export interface TermsOfServiceProps extends RouteComponentProps {
  name?: string
}

export const TermsOfService = (_: TermsOfServiceProps) => {
  const isSp = useMediaQuery({
    query: '(max-device-width: 500px)'
  })
  return (
    <Layout disableTermSelectbox back>
      <Box
        mx="auto"
        backgroundColor={Colors.bg.default}
        display="flex"
        justifyContent="center"
        py={isSp ? '0px' : '80px'}
      >
        <PageTermsOfService />
      </Box>
    </Layout>
  )
}
