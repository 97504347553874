import React from 'react'
import { Box } from '../'
import { IonText } from '@ionic/react'
import { Colors } from '../Color/Colors'
import { useMediaQuery } from 'react-responsive'
import { SP_MAX_WIDTH } from '../../../setting'

type Size = number | string
export interface TagProps {
  width?: Size
  height?: Size
  count?: string
  content: string
  contrast?: boolean
}

export const Tag = ({ count = '0', content, contrast = false }: TagProps) => {
  const isSp = useMediaQuery({
    query: `(max-device-width: ${SP_MAX_WIDTH})`
  })
  return (
    <Box display="flex">
      <Box
        display="flex"
        border={contrast ? `0.5px solid white` : `0.5px solid ${Colors.secondary.default}`}
        borderRadius="6px"
        overflow="hidden"
        cursor="pointer"
      >
        <Box
          display="flex"
          alignItems="center"
          color="white"
          backgroundColor={contrast ? 'white' : Colors.secondary.default}
          px="6px"
        >
          <IonText>
            {isSp ? (
              <h6 style={{ color: contrast ? Colors.primary.default : 'white', fontWeight: 'bold', fontSize: '10px' }}>
                {count}
              </h6>
            ) : (
              <h5 style={{ color: contrast ? Colors.primary.default : 'white', fontWeight: 'bold' }}>{count}</h5>
            )}
          </IonText>
        </Box>
        <Box px="5px" py={isSp ? '0px' : '1px'}>
          <IonText style={{ color: !contrast ? Colors.secondary.default : 'white', fontWeight: 'bold' }}>
            <p>{content}</p>
          </IonText>
        </Box>
      </Box>
    </Box>
  )
}
